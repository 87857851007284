import React from "react";
import { AiOutlineHome } from "react-icons/ai";
import { FaLongArrowAltRight } from "react-icons/fa";
import { Col, Container, Row } from "react-bootstrap";
import "../styles/slider.css";
import { LiaHandPointRight } from "react-icons/lia";

export const PlayGroup = () => {
  return (
    <>
      <div id="show_bg_298p">
        Play Group
        <ul className="no-bullets">
          <li>
            <a href="/">
              <AiOutlineHome /> &nbsp; Home
            </a>
          </li>
          <li>
            <a href="#home">
              <FaLongArrowAltRight /> &nbsp; Programmes
            </a>
          </li>
          <li>
            <a href="#home">
              <FaLongArrowAltRight /> &nbsp; Play Group
            </a>
          </li>
        </ul>
      </div>
      <Container>
        <br />
        <p style={{ fontSize: "35px", fontWeight: "600", color: "#ECB512" }}>
          Play Group(18months - 2.5years)
        </p>

        <p
          style={{ fontSize: "20px", textIndent: "50px", textAlign: "justify" }}
        >
          Playgroup is the seamless transition of a child into his first
          independent schooling experience sensitively separating from his/her
          primary caregiver. Our Playgroup curriculum is abundant in-play
          opportunities where children learn to freely explore, investigate and
          experience a wide range of sensory, creative and physical experiences
          while learning the value of focusing on a single task. The learning
          objectives ensure that children develop skills through activities that
          help them build early literacy skills and independence. Children also
          engage in a series of Co-curricular activities that lead to the
          overall development of the child. The student-teacher ratio of
          Playgroup is 10:1 and duration of this programme is 4 hours.
        </p>
        <p style={{ fontSize: "20px", textAlign: "justify" }}>
          {" "}
          <span
            style={{
              fontSize: "25px",
              fontWeight: "600",
              color: "#ECB512",
              textAlign: "justify",
            }}
          >
            Smart Interactive Learning
          </span>
          <br />
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Smart Book ignites that
          curiosity by introducing series of interactive books which empowers
          them to learn and revise independently in fun-filled ways. SmartBook
          bridges the gap between a child and a paper book, yet providing action
          and stimulation with sound. As we know children are immensely curious
          about their surroundings starting at a very young age, it uses
          examples from a child's day-to-day life as the starting point to
          examine different aspects, such as vegetables, fruits, animals, birds,
          colours and a lot more. Written in easy to understand and simple
          language, this book series is fun, interactive, educational and very
          exciting for the children who have just been introduced by reading.
        </p>
        <br />
        <p style={{ fontSize: "25px", fontWeight: "600", color: "#ECB512" }}>
          Topics covered
        </p>
        <p style={{ fontSize: "20px" }}>
          <LiaHandPointRight style={{ color: "#FF7F46", fontSize: "20px" }} />
          &nbsp; Letter A to Z
        </p>
        <p style={{ fontSize: "20px" }}>
          <LiaHandPointRight style={{ color: "#FF7F46", fontSize: "20px" }} />
          &nbsp; Animals & Birds
        </p>
        <p style={{ fontSize: "20px" }}>
          <LiaHandPointRight style={{ color: "#FF7F46", fontSize: "20px" }} />
          &nbsp; Vegetables & Fruits
        </p>
        <p style={{ fontSize: "20px" }}>
          <LiaHandPointRight style={{ color: "#FF7F46", fontSize: "20px" }} />
          &nbsp; Colours
        </p>
        <p style={{ fontSize: "20px" }}>
          <LiaHandPointRight style={{ color: "#FF7F46", fontSize: "20px" }} />
          &nbsp; Rhymes
        </p>
        <p style={{ fontSize: "20px" }}>
          <LiaHandPointRight style={{ color: "#FF7F46", fontSize: "20px" }} />
          &nbsp; 0 to 50
        </p>
        <p style={{ fontSize: "20px" }}>
          <LiaHandPointRight style={{ color: "#FF7F46", fontSize: "20px" }} />
          &nbsp; Picture Book
        </p>
        <p style={{ fontSize: "20px" }}>
          <LiaHandPointRight style={{ color: "#FF7F46", fontSize: "20px" }} />
          &nbsp; Sidhi Saral Hindi
        </p>

        <br />
        <br />
        <p style={{ fontSize: "25px", fontWeight: "600", color: "#ECB512" }}>
          Why Smart Book?
        </p>
        <p style={{ fontSize: "20px" }}>
          <LiaHandPointRight style={{ color: "#FF7F46", fontSize: "20px" }} />
          &nbsp; The concept behind the Smart Book - its content, design and
          technology is what simplifies even the most common topics like
          alphabet, colours, vegetables, fruits etc.
        </p>
        <p style={{ fontSize: "20px" }}>
          <LiaHandPointRight style={{ color: "#FF7F46", fontSize: "20px" }} />
          &nbsp; Interacting with Smart Book treats at least three out of the
          total five senses while television and computers usually occupy two.
          Seeing the visuals and then hearing about the same in a familiar
          voice, and eventually adding their own voice to it, is a great way of
          introducing the books into a kid's life.
        </p>
        <p style={{ fontSize: "20px" }}>
          <LiaHandPointRight style={{ color: "#FF7F46", fontSize: "20px" }} />
          &nbsp; Offers lots of fun, learning and engagement.
        </p>

        <img
          src={require("../asserts/playgroup1.jpg")}
          width={"100%"}
          alt="Third slide"
        />
      </Container>
    </>
  );
};
