import "./App.css";
import "./styles/Responsive.css";
import { Footer } from "./compoents/Footer";
import Navbar from "./compoents/Navbar";
import { Home } from "./Pages/Home";
import { About } from "./Pages/About";
import { DayCare } from "./Pages/DayCare";
import { PlayGroup } from "./Pages/PlayGroup";
import { Nursery } from "./Pages/Nursery";
import { PP1 } from "./Pages/PP1";
import Gallery from "./Pages/Gallery";
import { Team } from "./Pages/Team";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ContactUs from "./Pages/ContactUs";
import { Learning } from "./Pages/Learning";
import { Franch } from "./Pages/Franch";
import { Interactive } from "./Pages/Interactive";
import Achievements from "./Pages/Achievements";
import WhyUs from "./Pages/WhyUs";
import LetPartener from "./Pages/LetPartener";
import { Privacypolicy } from "./Pages/Privacypolicy";
import { TermsandCondition } from "./Pages/TermsandConditions";
import { FloatingWhatsApp } from "react-floating-whatsapp";
import { FaTwitter } from "react-icons/fa";
import { BiLogoFacebook } from "react-icons/bi";
import { BiLogoInstagram } from "react-icons/bi";
import { AiFillYoutube } from "react-icons/ai";

import logo1 from "../src/asserts/whatsapplogo.png";

function App() {
  return (
    <div className="App">
      <Router>
        <Navbar />

        <div class="float-sm">
          <div class="fl-fl float-fb">
            <FaTwitter style={{ fontSize: "30px", color: "#FFF" }} />
            <a href="https://twitter.com/KathalayaKids" target="_blank">
              {" "}
              &nbsp;Twitter
            </a>
          </div>
          <div class="fl-f2 float-tw">
            <BiLogoFacebook style={{ fontSize: "30px", color: "#FFF" }} />
            <a href="https://www.facebook.com/kathalayakids" target="_blank">
              &nbsp;Facebook
            </a>
          </div>
          <div class="fl-f3 float-gp">
            <BiLogoInstagram style={{ fontSize: "30px", color: "#FFF" }} />
            <a
              href="https://www.instagram.com/kathalaya_schools/"
              target="_blank"
            >
              &nbsp; Instagram
            </a>
          </div>
          <div class="fl-f4 float-rs">
            <AiFillYoutube style={{ fontSize: "30px", color: "#FFF" }} />
            <a
              href="https://www.youtube.com/@kathalayakids/featured"
              target="_blank"
            >
              &nbsp; You Tube
            </a>
          </div>
        </div>

        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/AboutUs" element={<About />} />
          <Route exact path="/DayCare" element={<DayCare />} />
          <Route exact path="/PlayGroup" element={<PlayGroup />} />
          <Route exact path="/Nursery" element={<Nursery />} />
          <Route exact path="/pp1&pp2" element={<PP1 />} />
          <Route exact path="/Gallery" element={<Gallery />} />
          <Route exact path="/Team" element={<Team />} />
          <Route exact path="/ContactUs" element={<ContactUs />} />
          <Route exact path="/Franchise" element={<Franch />} />
          <Route exact path="/Learning" element={<Learning />} />
          <Route exact path="/Interactive" element={<Interactive />} />
          <Route exact path="/Achievements" element={<Achievements />} />
          <Route exact path="/privacy" element={<Privacypolicy />} />
          <Route exact path="/TermsCondition" element={<TermsandCondition />} />
          <Route exact path="/whyus" element={<WhyUs />} />
          <Route exact path="/partner" element={<LetPartener />} />
        </Routes>
        <Footer />
        <FloatingWhatsApp
          phoneNumber="9133345709"
          accountName="KathalayaKids"
          allowEsc
          allowClickAway
          notification
          notificationSound
          avatar={logo1}
        />
      </Router>
    </div>
  );
}

export default App;
