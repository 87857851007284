import React from "react";
import CarouselSlider from "../compoents/Carosel";
import { Row, Col, Container } from "react-bootstrap";
import { ScrollRotate } from "react-scroll-rotate";
import "../styles/index.css";
import Slider from "react-slick";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { useState } from "react";
import Typewriter from "typewriter-effect";
import { Helmet } from "react-helmet";

export const Home = () => {
  const [show, setShow] = useState(true);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [isLoading, setIsLoading] = useState(true);

  const toggleIsLoading = () => {
    // 👇️ passed function to setState
    setIsLoading((current) => !current);
  };

  useEffect(() => {
    AOS.init();
  });

  const bg = {
    overlay: {
      background: "#fdf9f0",
    },
  };

  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Best Preschools in Hyderabad for Admissions</title>
        <meta
          name="keywords"
          content="admission in hyderabad sotrytelling school, Story learning school Admission open in hyderabad, Find Top Storification Schools In Hyderabad, Apply For School Admission, school admission in hyderabad, Best Schools in Hyderabad 2023"
        />
        [05:41 pm] Abdul Qadeer
        <meta
          name="description"
          content="Kathalaya Kids offers Pre-school admission form for the parents of Playgroup, Storification School. To enquiry or get the admission form visit Kathalaya Kids."
        />
      </Helmet>
      <CarouselSlider />

      <Container>
        <Row style={{ marginTop: "50px" }}>
          <p
            style={{
              textAlign: "center",
              fontSize: "30px",
              fontWeight: "600",
              color: "#ECB512",
            }}
            className="quote-2 typewriter"
          >
            <Typewriter
              options={{
                strings: ["A sneak peek into KATHALAYA"],
                autoStart: true,
                loop: true,
              }}
            />
          </p>
          <br />
          <br />
          <br />
          <br />

          <Col
            md={6}
            className="d-flex justify-content-center align-items-center"
            data-aos="fade-right"
            data-aos-duration="2000"
          >
            <img
              src={require("../asserts/25years.webp")}
              alt=""
              width="50%"
              data-aos="fade-right"
              data-aos-duration="2000"
            />
          </Col>
          <Col md={6}>
            <div className="youtube" style={{ backgroundColor: "#505a74" }}>
              <div
                style={{ width: "100%", height: "400px" }}
                className="d-flex justify-content-center align-items-center"
              >
                <iframe
                  style={{ borderRadius: "10px" }}
                  width="80%"
                  height="235"
                  src="https://www.youtube.com/embed/QBGOi_yourM"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
          </Col>
        </Row>
      </Container>

      <br />
      <br />
      <Container>
        <br />
        <div
          data-aos="fade-up"
          className="d-flex align-items-center justify-content-center"
        >
          <div
            style={{
              fontSize: "25px",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <span
              style={{
                color: "#ECB512",
                fontSize: "35px",
                textAlign: "center",
                fontweight: "800",
              }}
            >
              <p
                style={{
                  textAlign: "center",
                  fontSize: "60px",
                  fontWeight: "600",
                  color: "#ECB512",
                }}
              >
                Storification
              </p>
            </span>
            <hr style={{ width: "100%", align: "center" }} />
            “Pedagogical way of making learning more interesting and <br />
            engaging using the methodology of stories and storytelling.”
            <hr style={{ width: "100%", align: "center" }} />
            <br />
          </div>
        </div>
        <center>
          <ScrollRotate>
            <img src={require("../asserts/circle.webp")} alt="" width="60%" />
          </ScrollRotate>
        </center>
        <br />
        <br />
        <p
          style={{
            textAlign: "left",
            fontSize: "40px",
            fontWeight: "600",
            color: "#ECB512",
          }}
        >
          Fun learning at KATHALAYA
          <hr class="grunge-underline-fun"></hr>
        </p>

        <div id="gridc">
          <div className="cardc" data-aos="fade-right">
            <img
              src={require("../asserts/activity/creatives_activities.png")}
              width={"100%"}
              alt="Third slide"
            />
            <div className="d-flex justify-content-center align-items-center">
              <p className="gridbox">Colouring</p>
            </div>
          </div>
          <div
            className="cardc"
            data-aos="fade-right"
            data-aos-duration="500"
            data-aos-onhover="true"
          >
            <img
              src={require("../asserts/activity/creatives_activities1.png")}
              width={"100%"}
              alt="Third slide"
            />
            <div className="d-flex justify-content-center align-items-center">
              <p className="gridbox">Puppet Center</p>
            </div>
          </div>
          <div className="cardc" data-aos="fade-right" data-aos-duration="800">
            <img
              src={require("../asserts/activity/creatives_activities3.png")}
              width={"100%"}
              alt="Third slide"
            />
            <div className="d-flex justify-content-center align-items-center">
              <p className="gridbox">Table/Floor toys</p>
            </div>
          </div>
          <div className="cardc" data-aos="fade-right" data-aos-duration="1100">
            <img
              src={require("../asserts/activity/pottery.png")}
              width={"100%"}
              alt="Third slide"
            />
            <div className="d-flex justify-content-center align-items-center">
              <p className="gridbox">Pottery</p>
            </div>
          </div>
          <div className="cardc" data-aos="fade-right" data-aos-duration="1300">
            <img
              src={require("../asserts/activity/creatives_activities5.png")}
              width={"100%"}
              alt="Third slide"
            />
            <div className="d-flex justify-content-center align-items-center">
              <p className="gridbox">Music and Singing</p>
            </div>
          </div>
          <div className="cardc" data-aos="fade-left" data-aos-duration="1600">
            <img
              src={require("../asserts/activity/creatives_activities6.png")}
              width={"100%"}
              alt="Third slide"
            />
            <div className="d-flex justify-content-center align-items-center">
              <p className="gridbox">Reading den</p>
            </div>
          </div>
          <div className="cardc" data-aos="fade-left" data-aos-duration="1100">
            <img
              src={require("../asserts/activity/creatives_activities7.png")}
              width={"100%"}
              alt="Third slide"
            />
            <div className="d-flex justify-content-center align-items-center">
              <p className="gridbox">Clay play</p>
            </div>
          </div>
          <div className="cardc" data-aos="fade-left" data-aos-duration="800">
            <img
              src={require("../asserts/activity/creatives_activities8.png")}
              width={"100%"}
              alt="Third slide"
            />
            <div className="d-flex justify-content-center align-items-center">
              <p className="gridbox">Story time</p>
            </div>
          </div>
          <div className="cardc" data-aos="fade-left" data-aos-duration="500">
            <img
              src={require("../asserts/activity/creatives_activities9.png")}
              width={"100%"}
              alt="Third slide"
            />
            <div className="d-flex justify-content-center align-items-center">
              <p className="gridbox">Splash pool</p>
            </div>
          </div>
          <div className="cardc" data-aos="fade-left" data-aos-duration="900">
            <img
              src={require("../asserts/activity/more-sticker.jpg")}
              width={"100%"}
              alt="Third slide"
            />
            <div className="d-flex justify-content-center align-items-center">
              <p className="gridbox">Many More</p>
            </div>
          </div>
        </div>
      </Container>

      <br />
      <br />

      <p
        style={{
          textAlign: "center",
          fontSize: "60px",
          fontWeight: "600",
          color: "#ECB512",
        }}
      >
        Testimonial
      </p>
      <center>
        <hr class="grunge-underline"></hr>
      </center>
      <center>
        <div>
          <Slider {...settings}>
            <div style={{ backgroundColor: "#FF8047" }}>
              <div className="cardtest m-3">
                <p
                  style={{
                    fontSize: "20px",
                    padding: "10px",
                    textAlign: "justify",
                  }}
                >
                  "Interactive and interesting workshops for teachers, where
                  teachers learn different ways to tell stories, art of voice
                  modulation, facial expressions, making use of puppets and how
                  to make the class interactive through stories."{" "}
                  <b>- Sushma A.</b>
                </p>
                <img
                  data-aos="zoom-out-up"
                  style={{ position: "absolute", right: "0px", bottom: "0px" }}
                  src={require("../asserts/testmonial/T1.png")}
                  width={"10%"}
                />
              </div>
            </div>
            <div>
              <div className="cardtest m-3">
                <p
                  style={{
                    fontSize: "20px",
                    padding: "10px",
                    textAlign: "justify",
                  }}
                >
                  "The best pre-school with a perfect blend of traditional &
                  technology based learning catering the different learning
                  styles in children. The indoor and outdoor spaces are crafted
                  to aid your little one's development." <b>- Shruti S.</b>
                </p>
                <img
                  data-aos="zoom-out-up"
                  style={{ position: "absolute", right: "0px", bottom: "0px" }}
                  src={require("../asserts/testmonial/T2.png")}
                  width={"10%"}
                />
              </div>
            </div>
            <div>
              <div className="cardtest m-3">
                <p
                  style={{
                    fontSize: "20px",
                    padding: "10px",
                    textAlign: "justify",
                  }}
                >
                  "Story telling is a good way to improve children’s memory, it
                  makes them feel involved and the concept of teaching academics
                  through stories is a revolution in education."{" "}
                  <b>- Dattu K.</b>
                </p>
                <img
                  data-aos="zoom-out-up"
                  style={{ position: "absolute", right: "0px", bottom: "0px" }}
                  src={require("../asserts/testmonial/T3.png")}
                  width={"10%"}
                />
              </div>
            </div>
            <div>
              <div className="cardtest m-3">
                <p
                  style={{
                    fontSize: "20px",
                    padding: "9px",
                    textAlign: "justify",
                  }}
                >
                  "I wanted my child to have a well-rounded early education, and
                  this play school exceeded my expectations. The facilities are
                  fantastic, with a variety of play areas, learning materials,
                  and resources. I am grateful to this play school for laying a
                  strong foundation for my child's future." <b>- David M.</b>
                </p>
                <img
                  data-aos="zoom-out-up"
                  style={{ position: "absolute", right: "0px", bottom: "0px" }}
                  src={require("../asserts/testmonial/T4.png")}
                  width={"10%"}
                />
              </div>
            </div>
          </Slider>
        </div>
      </center>
      <Modal
        show={show}
        onHide={handleClose}
        size="xl"
        styles={bg}
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton>
          <Modal.Title>Are you looking for Admissions ?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <div className="row ">
              <div className="col-md-6 order-md-2">
                <div>
                  <iframe
                    src={"/Form.html"} // Adjust the path accordingly
                    width={"100%"} // Provide a specific width in pixels
                    height={"550px"}
                    title="Iframe Example"
                  ></iframe>
                </div>
              </div>
              <div className="col-md-6 order-md-1">
                <div>
                  <img
                    src={require("../asserts/admissions.webp")}
                    width={"100%"}
                  />
                </div>
              </div>
            </div>
          </Row>
        </Modal.Body>
      </Modal>
    </div>
  );
};
