import React from "react";
import { useState } from "react";
import "../styles/Gallary.css";
import { FaTimes } from "react-icons/fa";
import { LiaHandPointRight } from "react-icons/lia";
import { AiOutlineHome } from "react-icons/ai";
import { FaLongArrowAltRight } from "react-icons/fa";

const Achievements = () => {
  let data = [
    {
      id: 1,
      imgSrc:
        "https://firebasestorage.googleapis.com/v0/b/kathalaya-51358.appspot.com/o/Awards%2F1.webp?alt=media&token=e374bd81-b7e4-4310-899c-4c9f727b6494",
    },
    {
      id: 2,
      imgSrc:
        "https://firebasestorage.googleapis.com/v0/b/kathalaya-51358.appspot.com/o/Awards%2F2.webp?alt=media&token=85b22879-d436-4911-a415-b7b601c8a4aa",
    },
    {
      id: 3,
      imgSrc:
        "https://firebasestorage.googleapis.com/v0/b/kathalaya-51358.appspot.com/o/Awards%2F3.webp?alt=media&token=ece8283d-78f6-4b6f-aa81-0865dfb61cd2",
    },
    {
      id: 4,
      imgSrc:
        "https://firebasestorage.googleapis.com/v0/b/kathalaya-51358.appspot.com/o/Awards%2F4.webp?alt=media&token=9f272eac-f0e4-4592-81ae-29a4b4dbf120",
    },
    {
      id: 5,
      imgSrc:
        "https://firebasestorage.googleapis.com/v0/b/kathalaya-51358.appspot.com/o/Awards%2F5.webp?alt=media&token=2650b852-0600-4c7d-af1b-68cf38e27d23",
    },
    {
      id: 6,
      imgSrc:
        "https://firebasestorage.googleapis.com/v0/b/kathalaya-51358.appspot.com/o/Awards%2F6.webp?alt=media&token=f6c97657-4fad-456d-86f1-c80cde5720a5",
    },
    {
      id: 7,
      imgSrc:
        "https://firebasestorage.googleapis.com/v0/b/kathalaya-51358.appspot.com/o/Awards%2F7.webp?alt=media&token=0822b3d6-7a6b-435c-b5c5-10814cba81aa",
    },
    {
      id: 8,
      imgSrc:
        "https://firebasestorage.googleapis.com/v0/b/kathalaya-51358.appspot.com/o/Awards%2F8.webp?alt=media&token=f9288f51-8c60-4713-ba97-931587291945",
    },
    {
      id: 9,
      imgSrc:
        "https://firebasestorage.googleapis.com/v0/b/kathalaya-51358.appspot.com/o/Awards%2F9.webp?alt=media&token=d552ced8-12ac-44da-9140-1ecc958e649a",
    },
    {
      id: 10,
      imgSrc:
        "https://firebasestorage.googleapis.com/v0/b/kathalaya-51358.appspot.com/o/Awards%2F10.webp?alt=media&token=2c5a7ea7-2b04-454f-86a2-027f3a2f38cc",
    },
    {
      id: 11,
      imgSrc:
        "https://firebasestorage.googleapis.com/v0/b/kathalaya-51358.appspot.com/o/Awards%2F11.webp?alt=media&token=ff961997-c04c-4499-86e2-5e6413183d65",
    },
    {
      id: 12,
      imgSrc:
        "https://firebasestorage.googleapis.com/v0/b/kathalaya-51358.appspot.com/o/Awards%2F12.webp?alt=media&token=39d24c2b-3685-4964-abe1-fbf0de87b0a4",
    },
  ];
  const [model, setModel] = useState(false);
  const [tempimgSrc, setTempImgSrc] = useState("");

  const getImg = (imgSrc) => {
    setTempImgSrc(imgSrc);
    setModel(true);
  };
  return (
    <>
      <div id="show_bg_2982fr">
        Achievements & Awards
        <ul className="no-bullets">
          <li>
            <a href="/">
              <AiOutlineHome /> &nbsp; Home
            </a>
          </li>
          <li>
            <a href="#home">
              <FaLongArrowAltRight /> &nbsp; Why Kathalaya
            </a>
          </li>
          <li>
            <a href="#home">
              <FaLongArrowAltRight /> &nbsp; Achievements & Awards
            </a>
          </li>
        </ul>
      </div>

      <div id="gallery" style={{ marginTop: "50px" }}>
        <div className="container">
          <div className={model ? "model open" : "model"}>
            <img src={tempimgSrc} alt="" width="100%" />
            <FaTimes onClick={() => setModel(false)} />
          </div>
          <div className="gall">
            {data.map((item, index) => {
              return (
                <div
                  className="pics"
                  key={index}
                  onClick={() => getImg(item.imgSrc)}
                >
                  <img src={item.imgSrc} alt="Loading.." width="100%" />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default Achievements;
