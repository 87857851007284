import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import "../styles/Interactive.css";
import { LiaHandPointRight } from "react-icons/lia";
import { AiOutlineHome } from "react-icons/ai";
import { FaLongArrowAltRight } from "react-icons/fa";

export const Interactive = () => {
  return (
    <>
      <div id="show_bg_2982le">
        Smart Interactive Learning
        <ul className="no-bullets">
          <li>
            <a href="/">
              <AiOutlineHome /> &nbsp; Home
            </a>
          </li>
          <li>
            <a href="#home">
              <FaLongArrowAltRight /> &nbsp; Why Kathalaya
            </a>
          </li>
          <li>
            <a href="#home">
              <FaLongArrowAltRight /> &nbsp;Smart Interactive Learning
            </a>
          </li>
        </ul>
      </div>
      <Container style={{ marginTop: "50px" }}>
        <p
          style={{
            fontSize: "40px",
            fontWeight: "600",
            color: "#ECB512",
          }}
        >
          Why Smart Interactive Learning?
        </p>
        <p style={{ fontSize: "20px" }}>We provides Justified Learning.</p>
        <img
          src={require("../asserts/interactive.jpg")}
          width={"100%"}
          alt="Third slide"
        />

        <p
          style={{ fontSize: "20px", textIndent: "50px", textAlign: "justify" }}
        >
          <b>MELU SMART</b> Interactive books enables kids to learn by
          interacting versus just absorbing what is offered and often loosing
          retention in the process. Our books are made interactive with
          beautiful illustrations, touch points, and sounds. When children
          listen to the information in the form of story narration, the
          experience becomes more enjoyable. Moreover, images that can talk
          appeal more to a young child than the static illustrations in a book.
          And since it is all on paper, nothing can be safer. Our Smart
          Interactive books ignite the young minds to use their imagination to
          continue the story line and helps in child development. This unique
          process gives children the opportunity to use and develop their
          imagination and creativity in the most simple, interesting, and
          effective way.
        </p>
        <br />
        <p style={{ fontSize: "20px" }}>
          <b> Smart Reading pen </b>
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Listening to own
          voice is a major stimulator in kids. Besides being a catalyst to
          improve speech, it also encourages the child to listen patiently.
          While doing so, they are always in a process of learning, unknowingly!
          Learning as well as teaching is fun with <b>MELU</b> Smart Interactive
          books.
        </p>

        <p style={{ fontSize: "20px" }}>
          <b>Advantages of Smart pen:</b>
          <br />
          <p style={{ textIndent: "50px" }}>
            Assist the child to learn with the personalized learning assistance
            (talking pen), which delivers a structured learning to the child.
            The reading pen can provide the following:
          </p>

          <p>
            <LiaHandPointRight style={{ color: "#FF7F46", fontSize: "20px" }} />{" "}
            &nbsp; Interactive learning through rich audio content.
          </p>
          <p>
            <LiaHandPointRight style={{ color: "#FF7F46", fontSize: "20px" }} />{" "}
            &nbsp; Increases child’s listening and speaking capabilities.
          </p>
          <p>
            <LiaHandPointRight style={{ color: "#FF7F46", fontSize: "20px" }} />{" "}
            &nbsp;Learning is blended with fun.
          </p>
          <p>
            <LiaHandPointRight style={{ color: "#FF7F46", fontSize: "20px" }} />{" "}
            &nbsp;Improves creative and imaginative power of child.
          </p>
          <p>
            <LiaHandPointRight style={{ color: "#FF7F46", fontSize: "20px" }} />{" "}
            &nbsp;Improves child engagement and avoids distraction.
          </p>
          <p>
            <LiaHandPointRight style={{ color: "#FF7F46", fontSize: "20px" }} />{" "}
            &nbsp;Removes school anxiety.
          </p>
          <p>
            <LiaHandPointRight style={{ color: "#FF7F46", fontSize: "20px" }} />{" "}
            &nbsp; Listen and practice to learn the universal accepted
            pronunciation of words.
          </p>
          <p>
            <LiaHandPointRight style={{ color: "#FF7F46", fontSize: "20px" }} />
            &nbsp;Reduces screen time.
          </p>
          <p>
            <LiaHandPointRight style={{ color: "#FF7F46", fontSize: "20px" }} />
            &nbsp;Interactive trivia stimulates children to think out of the
            box.
          </p>
          <p>
            <LiaHandPointRight style={{ color: "#FF7F46", fontSize: "20px" }} />{" "}
            &nbsp;Children can have conversation with the books.
          </p>
          <p>
            <LiaHandPointRight style={{ color: "#FF7F46", fontSize: "20px" }} />
            &nbsp; Builds relationships between teacher and students, parents
            and children.
          </p>
          <p>
            <LiaHandPointRight style={{ color: "#FF7F46", fontSize: "20px" }} />
            &nbsp; Images of inner pages of books.
          </p>
        </p>

        <p style={{ fontSize: "20px" }}>
          {" "}
          Why are <b>MELU SMART INTERACTIVE</b> books future-ready compared to
          traditional non-interactive books?{" "}
        </p>

        <p style={{ fontSize: "20px", textIndent: "50px" }}>
          Future is of thought-leaders, observers and those who have their feet
          on the street.
        </p>

        <p
          style={{ fontSize: "20px", textAlign: "justify", textIndent: "50px" }}
        >
          Our books are not limited by a child’s ability to read because it
          talks to them. Just how we as parents and teachers talk to them. This
          helps us move them from simple to slightly higher level words,
          sentences and activities compared to normal text books. Hence, pushing
          the same child of 3 years of age to enjoy revising what he learnt at
          2.5 and also putting their feet into the world of a 3.5 year too.
        </p>

        <p
          style={{ fontSize: "20px", textAlign: "justify", textIndent: "50px" }}
        >
          The interaction design is woven in a way that observation cannot be
          avoided. Thinking cannot be bypassed and action cannot go undone. The
          kindergarteners have these skills very strong in them, till we almost
          quieten them for one or the other reason, knowingly or unknowingly.
          <b> MELU</b> does not just bring the skills of thinking, observing and
          taking action into the child, it even ensures that they are not lost.
        </p>
      </Container>
    </>
  );
};
