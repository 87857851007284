import React from "react";
import Lottie from "react-lottie";
import map from "../asserts/area-map.json";
import {
  FaClock,
  FaMapMarkerAlt,
  FaMobileAlt,
  FaEnvelopeOpenText,
} from "react-icons/fa";
import { Row, Col } from "react-grid-system";
import "../styles/contact.css";
import { AiOutlineHome } from "react-icons/ai";
import { FaLongArrowAltRight } from "react-icons/fa";
import "../styles/slider.css";

const ContactUs = () => {
  const emailAddress = "kathalayakidsinternational@gmail.com";
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: map,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <>
      <div id="show_bg_298contactus">
        Contact Us
        <ul className="no-bullets">
          <li>
            <a href="/">
              <AiOutlineHome /> &nbsp; Home
            </a>
          </li>

          <li>
            <a href="#home">
              <FaLongArrowAltRight /> &nbsp; Contact Us
            </a>
          </li>
        </ul>
      </div>

      <div id="ContactUs">
        <br />
        <br />
        <div className="container-fluid">
          <div>
            <div className="contact-bg">
              <h3>Get in Touch with Us</h3>
              <h2>contact us</h2>
              <div className="line1">
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>

            <div className="contact-body">
              <div className="contact-info">
                <div>
                  <span>
                    <FaMobileAlt className="fas" />
                  </span>
                  <span>Phone No.</span>
                  <span className="text">+91 9704444544</span>
                </div>
                <div>
                  <span>
                    <FaEnvelopeOpenText className="fas" />
                  </span>
                  <span>E-mail</span>
                  <span className="text">
                    <a
                      className="text-dark"
                      style={{ textDecoration: "none" }}
                      href={`mailto:${emailAddress}`}
                    >
                      kathalayakidsinternational@gmail.com
                    </a>
                  </span>
                </div>
                <div>
                  <span>
                    <FaMapMarkerAlt className="fas" />
                  </span>
                  <span>Address</span>
                  <span className="text">
                    Plot No. 78, Banjara Green Colony, Road No. 12, Tattikhana,
                    Banjara Hills, Hyderabad 500034.
                  </span>
                </div>
                <div>
                  <span>
                    <FaClock className="fas" />
                  </span>
                  <span>Opening Hours</span>
                  <span className="text">
                    Monday - Friday (9:00 AM to 5:00 PM)
                  </span>
                </div>
              </div>
            </div>

            <Row style={{ width: "100%" }}>
              <Col
                md={6}
                className="d-flex align-items-center justify-content-center text-center"
              >
                <div className="leftside">
                  <Lottie options={defaultOptions} width={"60%"} />
                </div>
              </Col>
              <Col md={6}>
                <h3 style={{ color: "#0082bc" }}>Let's connect!</h3>
                <iframe
                  src="https://script.google.com/macros/s/AKfycbwlCYC5zyxh7nyh54SJCUGK58xzUT9BOlS402ySx-j9o-RS6cSFQLI10Ec-lBTgS_ud-w/exec"
                  title="locationm"
                  width="100%"
                  height="550px"
                  style={{ border: 0 }}
                  aria-hidden="false"
                />
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </>
  );
};
export default ContactUs;
