import React from "react";
import "../styles/index.css";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { AiOutlinePhone } from "react-icons/ai";
import { CiMail } from "react-icons/ci";
import { FiMapPin } from "react-icons/fi";
import { Row, Col } from "react-bootstrap";
import logo from "../asserts/logo.png";
import { BsTwitter, BsFacebook, BsInstagram } from "react-icons/bs";

export const Footer = () => {
  return (
    <div style={{ marginTop: "180px" }}>
      <section class="pink">
        <Container>
          <div>
            <Row>
              <Col md={4}>
                <div className="logo ">
                  <Link className="logo-className" to={"/"}>
                    <img
                      src={logo}
                      alt="logo"
                      style={{ width: "60%", padding: "20px" }}
                    />
                  </Link>
                  <p
                    className="text-justify"
                    style={{ textAlign: "justify", color: "#FFF" }}
                  >
                    Step into a world where learning comes alive through
                    captivating stories, engaging activities and
                    boundless creativity.
                  </p>
                </div>
              </Col>

              <Col
                md={4}
                className="d-flex justify-md-content-center align-items-md-center justify-content-sm-start footer-item"
              >
                <div className="row">
                  <div className=" mb-2">
                    <h5
                      className="font-weight-bold  mb-2 footer-heading"
                      style={{ color: "#FFF" }}
                    >
                      Quick Links
                    </h5>
                    <div className="d-flex flex-column justify-content-start">
                      <Link
                        to={"/"}
                        style={{ color: "#FFF", textDecoration: "none" }}
                        className="list-item-link"
                      >
                        Home
                      </Link>
                      <Link
                        style={{ color: "#FFF", textDecoration: "none" }}
                        to="/aboutus"
                        className="list-item-link"
                      >
                        About Us
                      </Link>
                      <Link
                        style={{ color: "#FFF", textDecoration: "none" }}
                        to={"/Gallery"}
                        target="_blank"
                        className="list-item-link"
                      >
                        Gallery
                      </Link>
                      <Link
                        style={{ color: "#FFF", textDecoration: "none" }}
                        to={"/Team"}
                        target="_blank"
                        className="list-item-link"
                      >
                        Team
                      </Link>
                      <Link
                        style={{ color: "#FFF", textDecoration: "none" }}
                        to={"/Franch"}
                        className="list-item-link"
                      >
                        Business With Us
                      </Link>
                      <Link
                        style={{ color: "#FFF", textDecoration: "none" }}
                        to={"/contactus"}
                        className="list-item-link"
                      >
                        Contact Us
                      </Link>
                      <Link
                        style={{ color: "#FFF", textDecoration: "none" }}
                        to={"/Interactive"}
                        className="list-item-link"
                      >
                        Smart Interactive learning
                      </Link>
                      <Link
                        style={{ color: "#FFF", textDecoration: "none" }}
                        to={"/Learning"}
                        className="list-item-link"
                      >
                        Learning Enviornment
                      </Link>
                      <Link
                        style={{ color: "#FFF", textDecoration: "none" }}
                        to={"/PlayGroup"}
                        className="list-item-link"
                      >
                        Play Group
                      </Link>
                      <Link
                        style={{ color: "#FFF", textDecoration: "none" }}
                        to={"/DayCare"}
                        className="list-item-link"
                      >
                        Day Care
                      </Link>
                    </div>
                  </div>
                </div>
              </Col>

              <Col
                md={4}
                className="d-flex justify-md-content-center align-items-md-center justify-content-sm-start footer-item"
              >
                <div>
                  <h5
                    className="font-weight-bold  mb-3 footer-heading"
                    style={{ color: "#FFF" }}
                  >
                    Address
                  </h5>
                  <div className="d-flex flex-column ">
                    <p className="mb-2 d-flex gap-1" style={{ color: "#FFF" }}>
                      <span>
                        <FiMapPin />
                      </span>
                      Plot No. 78, Banjara Green Colony, Road No. 12,
                      <br />
                      Tattikhana, Banjara Hills, Hyderabad 500034.
                    </p>
                    <p className="mb-2 d-flex gap-1" style={{ color: "#FFF" }}>
                      <span>
                        <CiMail />
                      </span>
                      <a
                        href="mailto:kathalayakidsinternational@gmail.com
                        "
                        style={{ color: "#FFF", textDecoration: "none" }}
                      >
                        kathalayakidsinternational@gmail.com
                      </a>
                    </p>
                    <p className="mb-0 d-flex gap-1" style={{ color: "#FFF" }}>
                      <span>
                        <AiOutlinePhone />
                      </span>
                      <a
                        style={{ color: "#FFF", textDecoration: "none" }}
                        href="tel:9704444544"
                      >
                        9704444544
                      </a>
                    </p>
                    <div className="site-footer__social d-flex gap-3 mt-3">
                      <a
                        href="https://twitter.com/KathalayaKids"
                        target="_blank"
                        className="icons"
                      >
                        {" "}
                        <BsTwitter />
                      </a>
                      <a
                        href="https://www.facebook.com/kathalayakids"
                        target="_blank"
                        className="icons"
                      >
                        <BsFacebook />
                      </a>
                      <a
                        href="https://www.instagram.com/kathalaya_schools/"
                        target="_blank"
                        className="icons"
                      >
                        <BsInstagram />
                      </a>
                    </div>
                    <div className="my-2">
                      <h6 className=" text-white d-inline ">
                        <a
                          className="text-white"
                          style={{
                            textDecoration: "none",
                          }}
                          href="/privacy"
                        >
                          Privacy policy
                        </a>
                      </h6>

                      <h6 className=" text-white d-inline">
                        <a
                          className="text-white p-1"
                          style={{ textDecoration: "none" }}
                          href="/privacy"
                        >
                          |
                        </a>
                      </h6>

                      <h6 className=" text-white d-inline">
                        <a
                          className="text-white"
                          style={{ textDecoration: "none" }}
                          href="/TermsCondition"
                        >
                          Terms And Conditions
                        </a>
                      </h6>
                    </div>
                  </div>
                </div>
              </Col>
              <div className="row border-top border-light mx-xl-5 py-4">
                <div className=" px-xl-0 d-md-flex justify-content-between">
                  <p className="mb-md-0   text-white">
                    2023 &copy; All Rights Reserved.
                  </p>
                  <p className="text-white">
                    {" "}
                    Made With ❤ in india - CodeConnex
                  </p>
                </div>
              </div>
            </Row>
          </div>
        </Container>
      </section>
    </div>
  );
};
