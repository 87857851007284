import React from "react";
import { AiOutlineHome } from "react-icons/ai";
import { FaLongArrowAltRight } from "react-icons/fa";
import { LiaHandPointRight } from "react-icons/lia";
import { Col, Container, Row } from "react-bootstrap";

const WhyUs = () => {
  return (
    <>
      <div id="show_bg_298n">
        Why Us ?
        <ul className="no-bullets">
          <li>
            <a href="#home">
              <AiOutlineHome /> &nbsp; Home
            </a>
          </li>
          <li>
            <a href="#home">
              <FaLongArrowAltRight /> &nbsp; Why Kathalaya
            </a>
          </li>
          <li>
            <a href="#home">
              <FaLongArrowAltRight /> &nbsp; Why Us ?
            </a>
          </li>
        </ul>
      </div>
      <Container>
        <br />
        <p style={{ fontSize: "35px", fontWeight: "600", color: "#ECB512" }}>
          Make A Difference In Your Child's Early Learning
        </p>
        <p style={{ fontSize: "20px", textAlign: "justify" }}>
          <LiaHandPointRight style={{ color: "#FF7F46", fontSize: "20px" }} />
          &nbsp; We follow{" "}
          <b>International storywide pedagogy and activity based curriculum</b>.
        </p>
        <p style={{ fontSize: "20px", textAlign: "justify" }}>
          <LiaHandPointRight style={{ color: "#FF7F46", fontSize: "20px" }} />
          &nbsp;A curriculum with a perfect blend of Visual, Auditory and
          Kinaesthetic learning.
        </p>
        <p style={{ fontSize: "20px", textAlign: "justify" }}>
          <LiaHandPointRight style={{ color: "#FF7F46", fontSize: "20px" }} />
          &nbsp;Paying utmost importance to LSRW, laying a strong foundation for
          the pillars of effective communication.
        </p>
        <p style={{ fontSize: "20px", textAlign: "justify" }}>
          <LiaHandPointRight style={{ color: "#FF7F46", fontSize: "20px" }} />
          &nbsp;A typical day planner in <b>Kathalaya KIDS</b> is action-packed
          with lots of fun-filled engaging activities leading to the holistic
          development of children.
        </p>
        <p style={{ fontSize: "20px", textAlign: "justify" }}>
          <LiaHandPointRight style={{ color: "#FF7F46", fontSize: "20px" }} />
          &nbsp;We consider the individual needs, unique learning styles,
          interests and stage of development of each child to ensure that we
          engage our children in the learning process, challenging each child to
          develop skills and knowledge across the curriculum.
        </p>
        <p style={{ fontSize: "20px", textAlign: "justify" }}>
          <LiaHandPointRight style={{ color: "#FF7F46", fontSize: "20px" }} />
          &nbsp;Active participation is encouraged in a supportive, caring
          environment where children are taught to view mistakes as a natural
          part of the learning process.
        </p>
        <p style={{ fontSize: "20px", textAlign: "justify" }}>
          <LiaHandPointRight style={{ color: "#FF7F46", fontSize: "20px" }} />
          &nbsp;We work closely with parents, communicating daily and involving
          parents in the learning journey that is designed to take place both at
          school and at home.
        </p>
        <p style={{ fontSize: "20px", textAlign: "justify" }}>
          <LiaHandPointRight style={{ color: "#FF7F46", fontSize: "20px" }} />
          &nbsp;Committed & compassionate facilitators.
        </p>
        <p style={{ fontSize: "20px", textAlign: "justify" }}>
          <LiaHandPointRight style={{ color: "#FF7F46", fontSize: "20px" }} />
          &nbsp;Safe and secure environment.
        </p>
        <p style={{ fontSize: "20px", textAlign: "justify" }}>
          <LiaHandPointRight style={{ color: "#FF7F46", fontSize: "20px" }} />
          &nbsp;Well groomed campus.
        </p>
        <p style={{ fontSize: "20px", textAlign: "justify" }}>
          <LiaHandPointRight style={{ color: "#FF7F46", fontSize: "20px" }} />
          &nbsp;Impeccable hygiene & cleanliness.
        </p>
        <p style={{ fontSize: "20px", textAlign: "justify" }}>
          <LiaHandPointRight style={{ color: "#FF7F46", fontSize: "20px" }} />
          &nbsp;Learning through play-based inquiry.
        </p>
        <p style={{ fontSize: "20px", textAlign: "justify" }}>
          <LiaHandPointRight style={{ color: "#FF7F46", fontSize: "20px" }} />
          &nbsp;Parental Involvement.
        </p>
        <p style={{ fontSize: "20px", textAlign: "justify" }}>
          <LiaHandPointRight style={{ color: "#FF7F46", fontSize: "20px" }} />
          &nbsp;Personalised learning.
        </p>
        <p style={{ fontSize: "20px", textAlign: "justify" }}>
          <LiaHandPointRight style={{ color: "#FF7F46", fontSize: "20px" }} />
          &nbsp;Nutritious & well balanced diet plan.
        </p>
      </Container>
    </>
  );
};

export default WhyUs;
