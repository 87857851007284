import React, { useEffect, useRef } from "react";
import { Row, Col, Container, Navbar } from "react-bootstrap";
import { AiOutlineHome } from "react-icons/ai";
import { FaLongArrowAltRight } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import "../styles/slider.css";

export const Privacypolicy = () => {
  const location = useLocation();

  const someref = useRef(null);
  const emailAddress = "kathalayakidsinternational@gmail.com";
  useEffect(() => {
    if (someref.current) {
      someref.current.focus();
    }
  }, [location]);
  return (
    <>
      <div id="show_bg_privacy">
        Privacy Policy
        <ul className="no-bullets">
          <li>
            <a href="/">
              <AiOutlineHome /> &nbsp; Home
            </a>
          </li>
          <li>
            <a href="#home">
              <FaLongArrowAltRight /> &nbsp; Privacy Policy
            </a>
          </li>
        </ul>
      </div>
      <Container>
        <br />
        <Row>
          <p
            ref={someref}
            style={{
              fontSize: "40px",
              fontWeight: "600",
              color: "#ECB512",
            }}
          >
            Privacy Policy
          </p>
          {/* <p>
          The company CodeConnex, a data controller of your personal data, is the owner of the Kathalaya website.
          </p> */}
          <p
            style={{
              textAlign: "justify",
              textIndent: "50px",
              fontSize: "20px",
            }}
          >
            We have established this privacy policy, which outlines how we will
            use the data gathered by Kathalaya and explains why we need to
            gather certain personal information about you. Therefore, before
            using the Kathalaya website, please read our Privacy Policy.
          </p>
          <p
            style={{
              textAlign: "justify",
              textIndent: "50px",
              fontSize: "20px",
            }}
          >
            When lessons are communicated through stories that students identify
            with, and through meaningful child centric activities will be able
            to retain recall and retell concepts in education with ease and
            confidence.
          </p>
          <p style={{ textIndent: "50px", fontSize: "20px" }}>
            We safeguard your personal information and promise to keep it secure
            and confidential.
          </p>
          <p
            style={{
              fontSize: "40px",
              fontWeight: "600",
              color: "#ECB512",
            }}
          >
            Personal Information We Collect
          </p>
          <p
            style={{
              textAlign: "justify",
              textIndent: "50px",
              fontSize: "20px",
            }}
          >
            We automatically gather certain information about your device when
            you visit the <b>Kathalaya</b>, including information about your web
            browser, IP address, time zone, and some of the cookies that have
            been set up on your computer. We also keep track of the specific web
            pages or goods you view while using the Site, the websites or search
            keywords that led you there, and your interactions with the Site.
            This automatically gathered data is what we refer to as "Device
            Information." Additionally, in order to carry out the agreement, we
            may collect the personal information you give us upon registration
            (such as Name, Surname, Address, Payment Information, etc.).
          </p>
          <p
            style={{
              fontSize: "40px",
              fontWeight: "600",
              color: "#ECB512",
            }}
          >
            Why do we process your data?
          </p>
          <p
            style={{
              textAlign: "justify",
              textIndent: "50px",
              fontSize: "20px",
            }}
          >
            Security of client data is our primary priority, thus we only
            process the bare minimum amount of user data required to run and
            maintain the website. Only possible misuse incidents and statistical
            data about website usage are identified using the information
            gathered automatically. No alternative technique of aggregating this
            statistical data prevents it from being used to pinpoint a specific
            system user.
          </p>
          <p
            style={{
              textAlign: "justify",
              textIndent: "50px",
              fontSize: "20px",
            }}
          >
            You can browse the website without identifying yourself to us or
            disclosing any details that would allow someone to recognise you as
            a particular, identifiable person. However, if you want to utilise
            any of the website's features, sign up for our newsletter, or submit
            other information via a form, you'll need to give us personal
            information such as your email address, first and last name, city of
            residence, organisation, and phone number. You can opt not to
            provide us your personal information, but then you might not be able
            to use some of the services on the website. You won't be able to use
            the website to directly contact us or sign up for our newsletter,
            for instance. Users who are unsure about the information's source.
          </p>
          <p
            style={{
              fontSize: "40px",
              fontWeight: "600",
              color: "#ECB512",
            }}
          >
            Information Security
          </p>
          <p
            style={{
              textAlign: "justify",
              textIndent: "50px",
              fontSize: "20px",
            }}
          >
            We store the data you give us on computer servers in a secure,
            monitored environment that is guarded against unauthorised access,
            use or disclosure. In order to guard against unauthorised access,
            use, modification and disclosure of personal data under its control
            and custody, we maintain reasonable administrative, technical and
            physical safeguards. However, there is no guarantee for data
            transmission over a wireless network or the Internet.
          </p>
          <p
            style={{
              fontSize: "40px",
              fontWeight: "600",
              color: "#ECB512",
            }}
          >
            Legal Disclosure
          </p>
          <p
            style={{
              textAlign: "justify",
              textIndent: "50px",
              fontSize: "20px",
            }}
          >
            If required or permitted by law, such as to comply with a subpoena
            or other similar legal process, and when we reasonably believe that
            disclosure is necessary to protect our rights, protect your safety
            or the safety of others, investigate fraud, or satisfy a government
            request, we may disclose any information we collect, use, or
            receive.
          </p>
          <p
            style={{
              fontSize: "40px",
              fontWeight: "600",
              color: "#ECB512",
            }}
          >
            Contact Information
          </p>
          <p style={{ textIndent: "50px", fontSize: "20px" }}>
            Send an email to{" "}
            <a
              style={{ textDecoration: "none" }}
              href={`mailto:${emailAddress}`}
            >
              {emailAddress}
            </a>{" "}
            . if you have any questions about this Policy or want to get in
            touch with us about anything to do with your personal information or
            your rights as an individual.
          </p>
        </Row>
      </Container>
    </>
  );
};
