import React from "react";
import { AiOutlineHome } from "react-icons/ai";
import { FaLongArrowAltRight } from "react-icons/fa";
import { Col, Container, Row } from "react-bootstrap";

export const PP1 = () => {
  return (
    <>
      <div id="show_bg_298pp1">
        PP1 & PP2
        <ul className="no-bullets">
          <li>
            <a href="#home">
              <AiOutlineHome /> &nbsp; Home
            </a>
          </li>
          <li>
            <a href="#home">
              <FaLongArrowAltRight /> &nbsp; Programmes
            </a>
          </li>
          <li>
            <a href="#home">
              <FaLongArrowAltRight /> &nbsp; PP1 & PP2
            </a>
          </li>
        </ul>
      </div>
      <Container>
        <br />

        <p style={{ fontSize: "35px", fontWeight: "600", color: "#ECB512" }}>
          PP1(3.5years - 4.5years) & PP2(4.5years-5.5years)
        </p>
        <br />

        <p style={{ fontSize: "20px", color: "#000" }}>
          Let your little one’s be <b>ENGAGED AND EQUIPPED</b>!!
        </p>
        <br />
        <p
          style={{
            fontSize: "20px",
            color: "#000",
            textAlign: "justify",
            textIndent: "50px",
          }}
        >
          In PP1 and PP2, we follow the inquiry-based approach that facilitates
          children’s curiosity and experiential form of learning. Our curriculum
          is developed and aligned with the objectives of <b>NEP 2020</b>.
        </p>
        <br />
        <p
          style={{
            fontSize: "20px",
            color: "#000",
            textAlign: "justify",
            textIndent: "50px",
          }}
        >
          The classroom environment focuses on productivity, such as literacy
          skills, numeracy skills, environmental awareness and the co-curricular
          activities become integrated into the curriculum.
        </p>
        <br />
        <p
          style={{
            fontSize: "20px",
            color: "#000",
            textAlign: "justify",
            textIndent: "50px",
          }}
        >
          The curriculum focuses on the holistic growth of the developing child,
          imparting education through Storification methodology, equipping with
          a technological assistance to make them independent learners and
          accommodating play-based learning.
        </p>
        <br />
        <p
          style={{
            fontSize: "20px",
            color: "#000",
            textAlign: "justify",
            textIndent: "50px",
          }}
        >
          The environment acts as the third teacher, which is filled with
          purposeful materials and it promotes relationships, communication,
          collaboration, and exploration through play. Activity materials are
          thoughtfully added to the environment to promote creativity, thinking
          and problem solving. Through hands-on activities and learning through
          play and project work, children actively investigate their interests,
          discover new ideas, and develop their own theories. In keeping with
          our mission and values, teachers encourage and support each child’s
          unique style of learning, interests and talents.The curriculum in PP1
          and PP2 includes Literacy, Pre-math, EVS, Creative and Visual Arts and
          Personal, Social, Emotional and Physical development. Reading is an
          essential element in these programmes, and we follow a structured
          reading programme at school.
        </p>
        <br />
        <p
          style={{
            fontSize: "20px",
            color: "#000",
            textAlign: "justify",
            textIndent: "50px",
          }}
        >
          The curriculum encourages children to ask questions, enquire, plan
          investigations, explore various outcomes and discover credible and
          logical conclusions thereby equips the child future ready and
          independent learners with teachers acting as facilitators. Reading
          becomes an essential element in the Nursery program and children
          thoroughly enjoy experiential learning through various events and
          field trips. The student-teacher ratio in PP1 &PP2 is10:1, and the
          duration of this programme is 6 hours.
        </p>
        <br />
        <p
          style={{
            fontSize: "20px",
            color: "#000",
            textAlign: "justify",
            textIndent: "50px",
          }}
        >
          <b>Kathalaya Kids</b> facilitates a holistic approach towards
          learning, providing students with a range of learning and exploration
          experiences, in and beyond the classroom. These experiences promote
          and support the child’s personal, emotional, social, academic and
          physical development in a way that strikes a perfect balance between
          academics and activities.
        </p>
        <br />
        <p style={{ fontSize: "20px", color: "#000", textIndent: "50px" }}>
          {" "}
          For further information &details, please get in touch with us!
        </p>
      </Container>
    </>
  );
};
