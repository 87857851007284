import React from "react";
import { AiOutlineHome } from "react-icons/ai";
import { FaLongArrowAltRight } from "react-icons/fa";
import { Col, Container, Row } from "react-bootstrap";
import "../styles/slider.css";

export const Nursery = () => {
  return (
    <>
      <div id="show_bg_298n">
        Nursery
        <ul className="no-bullets">
          <li>
            <a href="#home">
              <AiOutlineHome /> &nbsp; Home
            </a>
          </li>
          <li>
            <a href="#home">
              <FaLongArrowAltRight /> &nbsp; Programmes
            </a>
          </li>
          <li>
            <a href="#home">
              <FaLongArrowAltRight /> &nbsp; Nursery
            </a>
          </li>
        </ul>
      </div>
      <Container>
        <br />
        <p style={{ fontSize: "35px", fontWeight: "600", color: "#ECB512" }}>
          Nursery (2.5years - 3.5years)
        </p>
        <br />
        <p style={{ fontSize: "20px", fontWeight: "600" }}>
          Let your little one’s EXPLORE!!
        </p>
        <br />
        <p style={{ fontSize: "20px", textIndent: "50px" }}>
          Our Nursery curriculum ensures the most robust foundation for future
          learning within a safe environment where every child can feel valued,
          confident and independent.
          <br />
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Our curriculum is
          developed and aligned with the objectives of NEP 2020.
        </p>
        <br />

        <p style={{ fontSize: "20px", textIndent: "50px" }}>
          The classroom environment focuses on productivity, such as literacy
          skills, numeracy skills, environmental awareness and the co-curricular
          activities become integrated into the curriculum.
        </p>
        <br />

        <p style={{ fontSize: "20px", textIndent: "50px" }}>
          Language skills of Listening, Speaking, Reading, Tracing& Writing has
          been given utmost importance in Kathalaya Nursery program.
          <br />
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The
          curriculum focuses on the holistic growth of the developing child,
          imparting education through Storification methodology, equipping with
          a technological assistance to make them independent learners and
          accommodating play-based learning.
        </p>
        <br />

        <p style={{ fontSize: "20px", textIndent: "50px" }}>
          The curriculum encourages children toask questions, enquire, plan
          investigations, explore various outcomes and discovercredible and
          logical conclusions, with teachers acting as facilitators.
        </p>
        <br />

        <p style={{ fontSize: "20px", textIndent: "50px" }}>
          Reading becomes an essential element in the Nursery program and
          children thoroughly enjoy experiential learning through various events
          and field trips. The student-teacher ratio in Nursery is 10:1, and the
          duration of this programme is 4 hours.
        </p>
        <br />
        <img
          src={require("../asserts/Nursery1.jpg")}
          width={"100%"}
          alt="Third slide"
        />
      </Container>
    </>
  );
};
