import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import "../styles/index.css";
import "../styles/Responsive.css";
import { useLocation } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { useState } from "react";

function NavScrollExample() {
  const location = useLocation();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const activeLinkStyle = {
    color: "#FF7F46",
    textColor: "#FF7F46",
    textDecoration: "underline",
    textDecorationThickness: "3px",
    fontWeight: "bold",
  };

  const itemStyle = {
    color: "#FF7F46",
    textDecoration: "underline",
    textDecorationThickness: "3px",
    fontWeight: "bold",
  };

  return (
    <>
      <Navbar
        expand="lg"
        className="navbar navbar navbar-expand-lg navbar-light sticky-top"
        style={{ backgroundColor: "#FFF" }}
      >
        <Container>
          <Navbar.Brand href="/">
            <img src={require("../asserts/logo.png")} alt="" width={"100%"} />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav
              className=" justify-content-center flex-grow-1 pe-3"
              style={{ fontSize: "16px", fontWeight: "500" }}
              navbarScroll
            >
              <Nav.Link
                href="/"
                style={location.pathname === "/" ? activeLinkStyle : null}
                className="list-item-link"
              >
                Home
              </Nav.Link>
              <Nav.Link
                href="/AboutUs"
                style={
                  location.pathname === "/AboutUs" ? activeLinkStyle : null
                }
                className="list-item-link"
              >
                About Us
              </Nav.Link>
              <NavDropdown
                className="list-item-link"
                title="Programmes"
                style={
                  location.pathname === "/DayCare" ||
                  location.pathname === "/PlayGroup" ||
                  location.pathname === "/Nursery" ||
                  location.pathname === "/pp1&pp2"
                    ? activeLinkStyle
                    : null
                }
                id="navbarScrollingDropdown"
              >
                <NavDropdown.Item
                  href="/DayCare"
                  style={location.pathname === "/DayCare" ? itemStyle : null}
                >
                  Day Care
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item
                  href="/PlayGroup"
                  style={location.pathname === "/PlayGroup" ? itemStyle : null}
                >
                  Play Group
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item
                  href="/Nursery"
                  style={location.pathname === "/Nursery" ? itemStyle : null}
                >
                  Nursery
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item
                  href="/pp1&pp2"
                  style={location.pathname === "/pp1&pp2" ? itemStyle : null}
                >
                  PP1 & PP2
                </NavDropdown.Item>
              </NavDropdown>

              <Nav.Link
                href="/Franchise"
                className="list-item-link"
                style={
                  location.pathname === "/Franchise" ? activeLinkStyle : null
                }
              >
                Franchise Opportunity
              </Nav.Link>
              <NavDropdown.Divider />
              <NavDropdown
                className="list-item-link"
                title="Why Kathalaya"
                style={
                  location.pathname === "/Learning" ||
                  location.pathname === "/Gallery" ||
                  location.pathname === "/whyus" ||
                  location.pathname === "/Interactive" ||
                  location.pathname === "/Achievements" ||
                  location.pathname === "/Team" ||
                  location.pathname === "/partner"
                    ? activeLinkStyle
                    : null
                }
                id="navbarScrollingDropdown"
              >
                <NavDropdown.Item
                  href="/Learning"
                  style={location.pathname === "/Learning" ? itemStyle : null}
                >
                  The Learning Environment
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item
                  href="/whyus"
                  style={location.pathname === "/whyus" ? itemStyle : null}
                >
                  Why US &nbsp;?
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item
                  href="/Interactive"
                  style={
                    location.pathname === "/Interactive" ? itemStyle : null
                  }
                >
                  Why Smart Interactive learning
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item
                  href="/Achievements"
                  style={
                    location.pathname === "/Achievements" ? itemStyle : null
                  }
                >
                  Achievements &amp; Awards
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item
                  href="/Team"
                  style={location.pathname === "/Team" ? itemStyle : null}
                >
                  Business With Us
                </NavDropdown.Item>
                <NavDropdown.Divider />

                <NavDropdown.Item
                  href="/partner"
                  style={location.pathname === "/partner" ? itemStyle : null}
                >
                  Let’s Partner Up ?
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item
                  href="/Gallery"
                  style={location.pathname === "/Gallery" ? itemStyle : null}
                >
                  Gallery
                </NavDropdown.Item>
              </NavDropdown>

              <Nav.Link
                href="/ContactUs"
                className="list-item-link"
                style={
                  location.pathname === "/ContactUs" ? activeLinkStyle : null
                }
              >
                Contact Us
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
          <button id="btn-pre-v2" onClick={handleShow}>
            ENQUIRE NOW
          </button>
        </Container>
      </Navbar>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Enquire Now</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="holds-the-iframe">
            <iframe
              src="https://script.google.com/macros/s/AKfycbwlCYC5zyxh7nyh54SJCUGK58xzUT9BOlS402ySx-j9o-RS6cSFQLI10Ec-lBTgS_ud-w/exec"
              title="locationm"
              width="100%"
              height="550px"
              style={{ border: 0 }}
              aria-hidden="false"
            />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default NavScrollExample;
