import React from "react";
import { Row, Col } from "react-bootstrap";
import "../styles/Team.css";
import { AiOutlineHome } from "react-icons/ai";
import { FaLongArrowAltRight } from "react-icons/fa";
import "../styles/slider.css";

export const Team = () => {
  return (
    <>
      <div id="show_bg_298ppte">
        Team
        <ul className="no-bullets">
          <li>
            <a href="/">
              <AiOutlineHome /> &nbsp; Home
            </a>
          </li>

          <li>
            <a href="#home">
              <FaLongArrowAltRight /> &nbsp; Team
            </a>
          </li>
        </ul>
      </div>
      <br />
      <div className="container">
        <div id="gridt">
          <div className="flip-card">
            <div className="flip-card-inner">
              <div className="flip-card-front">
                <img
                  src={require("../asserts/team/Geetha-Ramanujam-garu.png")}
                  alt=" "
                  width={"100%"}
                />
                <div>
                  <p style={{ fontWeight: "700", color: "#1faceb" }}>
                    MRS. GEETA RAMANUJAM M ED. <br />
                    <span
                      style={{
                        color: "#000",
                        fontSize: "15px",
                        fontWeight: "400",
                      }}
                    >
                      Founding Director & Academic Head
                    </span>
                  </p>
                </div>
              </div>

              <div className="flip-card-back d-flex justify-content-center align-items-center">
                <p style={{ textAlign: "justify", padding: "10px" }}>
                  Geeta Ramanujam ,is a Master Storyteller, Trainer Founder and
                  Executive Director of Kathalaya’s International Academy of
                  Storytelling and Kathalaya Trust. She is also an Academician
                  and Administrator with 24 years of experience in Storytelling.
                  She is a Pioneer of the Storytelling movement in India having
                  trained 85,000 people in the Art. She has traveled to 48
                  countries around the World and 27 states in India to groom
                  professional tellers- sowing seeds of Storytelling around the
                  world for the last 40 years.
                </p>
              </div>
            </div>
          </div>

          <div class="flip-card">
            <div class="flip-card-inner">
              <div class="flip-card-front">
                <img
                  src={require("../asserts/team/Srinivas-garu.png")}
                  alt=" "
                  width={"100%"}
                />
                <div>
                  <p style={{ fontWeight: "700", color: "#1faceb" }}>
                    DR SRINIVASA KANDULA MBBS DNB ECFMG ( USA)
                    <br />
                    <span
                      style={{
                        color: "#000",
                        fontSize: "15px",
                        fontWeight: "400",
                      }}
                    >
                      Managing Director
                    </span>
                  </p>
                </div>
              </div>
              <div class="flip-card-back d-flex justify-content-center align-items-center">
                <p style={{ textAlign: "justify", padding: "10px" }}>
                  Dr. Srinivasa Yadav Kandula is a general surgeon by
                  profession. He did his graduation (MBBS) from Rangaraya
                  Medical College, NTR University of Health Sciences in the year
                  2007. He got ECFMG (Education Commission For Foreign Medical
                  Graduates) Certificate from USA in the year 2009, worked as a
                  voluntary clinical research associate at Baylor College Of
                  Medicine, Houston, Texas from 2010 to 2011. Later he did
                  Postgraduation in General Surgery from South Central Railway
                  Hospital, National Board of Examinations, New Delhi.
                </p>
              </div>
            </div>
          </div>

          <div class="flip-card">
            <div class="flip-card-inner">
              <div class="flip-card-front">
                <img
                  src={require("../asserts/team/suma-Madam-garu.png")}
                  alt=" "
                  width={"100%"}
                />
                <div>
                  <p style={{ fontWeight: "700", color: "#1faceb" }}>
                    DR SREE SUMA GORIPARTHY MBBS, DGO, DNB
                    <br />
                    <span
                      style={{
                        color: "#000",
                        fontSize: "15px",
                        fontWeight: "400",
                      }}
                    >
                      Managing Director
                    </span>
                  </p>
                </div>
              </div>
              <div class="flip-card-back d-flex justify-content-center align-items-center">
                <p style={{ textAlign: "justify", padding: "10px" }}>
                  Dr. Sree Suma Goriparthy is a gynaecologist by profession. She
                  worked as a Senior Resident doctor at Rainbow birthplace
                  Kharkhana, secunderabad With the passion towards promoting the
                  health, to bring awareness in people about the healthy life
                  style , she established the Medilight Private Ltd in the year
                  2016.Her sons speech delay has made her to research more about
                  the early brain development and early child hood education and
                  with that motivation she established Medilight Education Pvt
                  ltd in 2019.
                </p>
              </div>
            </div>
          </div>

          <div class="flip-card">
            <div class="flip-card-inner">
              <div class="flip-card-front">
                <img
                  src={require("../asserts/team/Santosh-garu.png")}
                  alt=" "
                  width={"100%"}
                />
                <div>
                  <p style={{ fontWeight: "700", color: "#1faceb" }}>
                    MR. SANTOSH KUMAR ANUVALASETTY
                    <br />
                    <span
                      style={{
                        color: "#000",
                        fontSize: "15px",
                        fontWeight: "400",
                      }}
                    >
                      Executive Director & Finance Head
                    </span>
                  </p>
                </div>
              </div>
              <div class="flip-card-back d-flex justify-content-center align-items-center">
                <p style={{ textAlign: "justify", padding: "10px" }}>
                  Mr. Santosh Kumar Anuvalasetty is an Executive director and
                  co-founder for Medilight Group of companies established in the
                  year 2016 and since then he is heading entire operation of
                  companies related to accounts department and excelling as
                  chief accounts officer as well. He is a microbiologist turned
                  into a business entrepreneur with an experience of over 15
                  years into running his family business and self-made person
                  from a small town called Tenali with an ideology of promoting
                  two main elements of mankind that is health and education.{" "}
                </p>
              </div>
            </div>
          </div>

          <div class="flip-card">
            <div class="flip-card-inner">
              <div class="flip-card-front">
                <img
                  src={require("../asserts/team/Sridhar-Garu.png")}
                  alt=" "
                  width={"100%"}
                />
                <div>
                  <p style={{ fontWeight: "700", color: "#1faceb" }}>
                    MR. SRIDHAR KURAGAYALA
                    <br />
                    <span
                      style={{
                        color: "#000",
                        fontSize: "15px",
                        fontWeight: "400",
                      }}
                    >
                      Marketing Director
                    </span>
                  </p>
                </div>{" "}
              </div>
              <div class="flip-card-back d-flex justify-content-center align-items-center">
                <p style={{ textAlign: "justify", padding: "10px" }}>
                  Mr. Sridhar Kuragayala, Dynamic Director of Marketing & Sales
                  with over 12 years of experience in developing and executing
                  marketing strategies in education and telecom industry,
                  seeking to improve brand promotion and recognition.
                  Accomplished Sales & Marketing business with a background of
                  success. Experienced in highly competitive markets developing
                  customized marketing strategies, managing complex sales,
                  penetrating new markets and expanding current business. He has
                  done M.B.A in Marketing from Osmania University.
                </p>
              </div>
            </div>
          </div>

          <div class="flip-card">
            <div class="flip-card-inner">
              <div class="flip-card-front">
                <img
                  src={require("../asserts/team/supriya-Garu.png")}
                  alt=" "
                  width={"100%"}
                />
                <div>
                  <p style={{ fontWeight: "700", color: "#1faceb" }}>
                    MRS SUPRIYA KURAGAYALA
                    <br />
                    <span
                      style={{
                        color: "#000",
                        fontSize: "15px",
                        fontWeight: "400",
                      }}
                    >
                      Academic Program Director
                    </span>
                  </p>
                </div>
              </div>
              <div class="flip-card-back d-flex justify-content-center align-items-center">
                <p style={{ textAlign: "justify", padding: "10px" }}>
                  Mrs. Supriya is an avid learner. Working at MELU as Curriculum
                  Head, she is zealous about training the teachers with right
                  values. She strongly believes that learning can happen from
                  every single person and the environment. She enjoys being with
                  children and a believer of Play way methodology. An extremely
                  passionate person, with an interesting academic background of
                  M.B.A , that of being working with major MNC’s for a brief
                  period. But an immense zeal and interest towards education in
                  early learners, did Post Graduate Diploma in Pre & Primary
                  Education, Child psychology and a passionate teacher with more
                  than 5 years of experience of teaching.
                </p>
              </div>
            </div>
          </div>

          <div class="flip-card">
            <div class="flip-card-inner">
              <div class="flip-card-front">
                <img
                  src={require("../asserts/team/Sonam-garu.png")}
                  alt=" "
                  width={"100%"}
                />
                <div>
                  <p style={{ fontWeight: "700", color: "#1faceb" }}>
                    DR SONAM BDS, MBA ( HOSP ADMIN)
                    <br />
                    <span
                      style={{
                        color: "#000",
                        fontSize: "15px",
                        fontWeight: "400",
                      }}
                    >
                      Chief Of Operations
                    </span>
                  </p>
                </div>
              </div>
              <div class="flip-card-back d-flex justify-content-center align-items-center">
                <p style={{ textAlign: "justify", padding: "10px" }}>
                  Dr.Sonam is a dental surgeon by profession, passionate into
                  healthcare and education. She is currently holding the
                  position of Chief of operations at Medilight Group of
                  companies, being a mother of 5 year old kid, her interest
                  grows towards the preprimary education that made her to
                  involve more into the curriculum and teaching methodologies
                  research and development happening at Medilight education.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
