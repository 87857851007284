import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import "../styles/Business.css";
import { AiOutlineHome } from "react-icons/ai";
import { FaLongArrowAltRight } from "react-icons/fa";
import "../styles/slider.css";
import { Helmet } from "react-helmet";

export const Franch = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Best Sorification | Story telling School in india</title>
        <meta
          name="keywords"
          content="schools franchises, school franchise, schools franchise business, school franchise opportunities, schools, schools franchise opportunities, franchise India, franchising, franchise, franchisor, best school franchise, top school"
        />
        <meta
          name="description"
          content=" School Franchise. With full set-up and training assistance, Kathalaya Kids stands as the Best Education Franchise Opportunities in India. Grab this one-time investment Opportunity and transform the nation through education "
        />
      </Helmet>
      <br />
      <Container>
        <div className="row ">
          <div className="col-md-4 order-md-2 d-flex jutify-content-center align-items-center">
            <div
              style={{
                backgroundColor: "#fb7f48",
                padding: "20px",
                borderRadius: "10px",
              }}
            >
              <iframe
                src={"/Form1.html"}
                width={"100%"}
                height={"400px"}
                title="Iframe Example"
              ></iframe>
            </div>
          </div>
          <div className="col-md-8 order-md-1 d-flex jutify-content-center align-items-center">
            <div>
              <p
                style={{
                  fontSize: "35px",
                  fontWeight: "600",
                  color: "#ECB512",
                }}
              >
                Why Should I Become A Franchise ?
              </p>
              <p style={{ fontSize: "20px", textIndent: "50px" }}>
                {" "}
                <b>KATHALAYA INTERNATIONAL ACADEMY OF STORYTELLING</b> has 25
                years of experience in Storytelling and has trained more than 1
                lakh teachers pan India.
              </p>
              <p style={{ fontSize: "20px", textIndent: "50px" }}>
                The key reasons for the success that <b>KATHALAYA</b> has
                achieved is its structured <b>STORIFIED</b> curriculum, focus on
                innovation, and strong commitment to delivering quality
                education with integrity.
              </p>
              <p style={{ fontSize: "20px" }}>
                <p>
                  {" "}
                  There are several advantages of being a part of the{" "}
                  <b>KATHALAYA </b>
                  family:
                </p>
              </p>
              <p style={{ fontSize: "20px" }}>
                1. Be a part of a brand that has been in the field of
                Storytelling education for 25 years.
              </p>
              <p style={{ fontSize: "20px" }}>
                2. Extensive and personalized support from an established
                educational brand resulting in a higher success rate.
              </p>
            </div>
          </div>
        </div>

        <Row>
          <Col md={8}></Col>
          <Col
            md={4}
            className="d-flex justify-content-center align-items-center"
          ></Col>
          <p style={{ fontSize: "20px" }}>
            3. Hand-holding by Franchisee Supervisors who play the role of
            &nbsp;
            <b>MENTOR</b> and are the contact point for day-to-day
            queries/support.
          </p>
          <p style={{ fontSize: "20px" }}>
            4. Unique Add-On revenue models ensure maximisation of the premise
            utilization, therefore enhancing the ROI.
          </p>
          <p style={{ fontSize: "20px" }}>
            5. Regular e-mail contact/support/encouragement/new ideas from
            Academic/marketing Departments of Franchisee Cell.
          </p>
          <p style={{ fontSize: "20px" }}>
            6. Easy simple reporting formats to help ensure every{" "}
            <b>KATHALAYA</b>
            &nbsp; Centre maximises potential.
          </p>
          <p style={{ fontSize: "20px" }}>
            7. Continual event support for all celebrations.
          </p>
          <p style={{ fontSize: "20px" }}>
            8. Unique parental involvement ideas/techniques to help establish
            Centre through local buzz..
          </p>
          <p style={{ fontSize: "20px" }}>
            9. We will provide the franchisee with detailed manuals for every
            operational need of the center.
          </p>
          <p style={{ fontSize: "20px" }}>
            10. We will provide trusted vendors to the franchisee in the
            purchase of admission kits, uniforms, furniture etc.
          </p>
          <p style={{ fontSize: "20px" }}>
            11. Extensive academic support will be given to the franchisee as we
            will provide them with our research based curriculum and detailed
            session plans, which is continuously updated by our academic team at
            the corporate office.
          </p>
          <p style={{ fontSize: "20px" }}>
            12. Teacher’s guidelines for curricular and extracurricular
            activities will be provided.
          </p>
          <p style={{ fontSize: "20px" }}>
            13. Unique Admission Kit designed to facilitate education process
            invlolving child, parent and the individual Centre.
          </p>
          <p style={{ fontSize: "20px" }}>
            14. Regular academic meets / updates/ audits to ensure best
            practices / high standards.
          </p>
          <p style={{ fontSize: "20px" }}>
            15. Extensive word of mouth publicity will be generated through our
            already existing schools.
          </p>
        </Row>
      </Container>
    </>
  );
};
