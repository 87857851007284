import React from "react";
import { Container } from "react-bootstrap";
import { AiOutlineHome } from "react-icons/ai";
import { FaLongArrowAltRight } from "react-icons/fa";

const LetPartener = () => {
  return (
    <>
      <div id="show_bg_2982fr">
        Let's Partner Up ?
        <ul className="no-bullets">
          <li>
            <a href="/">
              <AiOutlineHome /> &nbsp; Home
            </a>
          </li>

          <li>
            <a href="#home">
              <FaLongArrowAltRight /> &nbsp; Why Kathalaya
            </a>
          </li>

          <li>
            <a href="#home">
              <FaLongArrowAltRight /> &nbsp; Let's Partner Up ?
            </a>
          </li>
        </ul>
      </div>
      <Container>
        <br />
        <p style={{ fontSize: "35px", fontWeight: "600", color: "#ECB512" }}>
          Let's Partner Up
        </p>

        <p
          style={{ textAlign: "justify", fontSize: "20px", textIndent: "50px" }}
        >
          <b>KATHALAYA KIDS</b> is happy to partner with you to set up a
          preschool in your city with a vision to bring innovation in the Early
          Years education. Our education franchise business plan is aligned to
          our legacy and reputation in the sphere of education, and we are
          looking at partners who are passionate to create nurturing, creative
          learning spaces to build conscious leaders of tomorrow.
        </p>
        <br />
        <p
          style={{ textAlign: "justify", fontSize: "20px", textIndent: "50px" }}
        >
          It is important that our potential franchisee partners meet specific
          requirements to ensure that we work together to carry the same
          reputation forward and achieve success.
        </p>
        <br />
        <p
          style={{ textAlign: "justify", fontSize: "20px", textIndent: "50px" }}
        >
          If you are looking for a unique storified curriculum, play oriented,
          child centric, stress free smart education system then Kathalaya is
          the right choice for you.
        </p>
      </Container>
    </>
  );
};

export default LetPartener;
