import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import "../styles/slider.css";
import { AiOutlineHome } from "react-icons/ai";
import { LiaHandPointRight } from "react-icons/lia";
import { FaLongArrowAltRight } from "react-icons/fa";
import { Helmet } from "react-helmet";

export const About = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Kathalaya Kids School Admission In hyderabad</title>
        <meta
          name="keywords"
          content="Best School in Hyderabad, What is Storification School, Storytelling School in hyderabad, Kids Play School in india, School Franchise in india"
        />
        <meta
          name="description"
          content="25 years legacy of kathalaya, kathalaya international academy of storytelling, certified teacher training course in story telling,  trained over 1,00,000 teachers, successful journey of 25 years, kathalaya kids international pre-school "
        />
      </Helmet>

      <div id="show_bg_2982">
        About Us
        <ul className="no-bullets">
          <li>
            <a href="/">
              <AiOutlineHome /> &nbsp; Home
            </a>
          </li>

          <li>
            <a href="#home">
              <FaLongArrowAltRight /> &nbsp; About Us
            </a>
          </li>
        </ul>
      </div>
      <Container>
        <br />

        <p style={{ fontSize: "35px", fontWeight: "600", color: "#ECB512" }}>
          Transforming education through{" "}
          <span style={{ fontWeight: "800" }}> Storification! </span>
        </p>
        <br />
        <Row>
          <h1 className="homeheading">Story of Kathalaya</h1>

          <span
            style={{
              fontSize: "20px",
              margin: "0px",
              padding: "12px",
              justifyContent: "center",
              textAlign: "justify",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;A thousand eons ago
            Prarambh – the lord of all beginnings created Bhoomi–The Earth.
            Prarambh covered Bhoomi with trees as temples of patience, the seas
            for peace and decorated the Earth with a carpet of colourful
            flowers. He introduced music through birds and sounds through
            animals. Time passed by and one fine morning Prarambh found a seed
            of wisdom. “Why not sow the seeds of wisdom in Maanush the first
            man. ’’As he pondered the winds of change brought stories from
            Triloka- the three worlds. The creator then collected the myths,
            legends, epics, fables and preserved them in a huge chest which he
            named “<b>KATHAKAAL</b>.” He chose Aditi –to be the Goddess of the
            chest. This Chest of tales was then passed down from her to other
            folks orally through the ages in the form of folk tales and
            folklores as each region evolved their own telling styles. In 1996
            the 999th Avatar of Aditi to receive this chest was young maiden
            named Geeta Ramanujam. Geeta from her young days was a curious lad
            and asked many How and Why questions.“Why should I sit in the same
            place in a classroom hour after hour?” Her parents tried to provide
            the reasons but Geeta was not satisfiedwith their responses.
            <br />
            <br />
            <p>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;She grew up to be
              a teacher and a librarian in a school. One rainy day in the month
              of June in 1996 three teachers ran towards a huge banyan tree in
              the corner of the school compound to take shelter under from the
              heavy downpour. The conversation led to questions.
            </p>
            <p>
              "Why are there communication gaps in Education ? " <br /> "Why is
              teaching so monotonous ? " <br />
              "Why is Education merely information based on and not base on
              practical or Experiential Learning ? "
              <br /> “How does one make learning joyous for children ? "<br />
              The rain stopped and there was a serene silence. Her friends left
              as Geeta watched a caterpillar moving around the leaves trying to
              build a cocoon. She also observed a butterfly settle on a flower.
              <br />
              Bingo the answer emerged-
              <b>
                STORY TELLING. <br /> Kathalaya "The house of stories"
              </b>
              was born in 1998.
            </p>
            <p
              style={{
                fontSize: "35px",
                fontWeight: "600",
                color: "#ECB512",
                textAlign: "center",
              }}
            >
              25 YEARS Legacy of KATHALAYA
            </p>
            <div className="d-flex justify-content-center align-items-center">
              <div
                style={{
                  borderColor: "#000",
                  borderStyle: "solid",
                  padding: "20px",
                }}
              >
                <p>
                  {" "}
                  <LiaHandPointRight
                    style={{ color: "#FF7F46", fontSize: "20px" }}
                  />
                  &nbsp;KATHALAYA INTERNATIONAL ACADEMY OF STORYTELLING{" "}
                </p>
                <p>
                  {" "}
                  <LiaHandPointRight
                    style={{ color: "#FF7F46", fontSize: "20px" }}
                  />
                  &nbsp;CERTIFIED TEACHER TRAINING COURSE IN STORY TELLING
                </p>
                <p>
                  {" "}
                  <LiaHandPointRight
                    style={{ color: "#FF7F46", fontSize: "20px" }}
                  />
                  &nbsp;TRAINED OVER 1,00,000 TEACHERS
                </p>
                <p>
                  <LiaHandPointRight
                    style={{ color: "#FF7F46", fontSize: "20px" }}
                  />
                  &nbsp; SUCCESSFUL JOURNEY OF 25 YEARS
                </p>
                <p>
                  <LiaHandPointRight
                    style={{ color: "#FF7F46", fontSize: "20px" }}
                  />
                  &nbsp; KATHALAYA KIDS INTERNATIONAL PRE-SCHOOL
                </p>
              </div>
            </div>
          </span>
        </Row>
        <Row>
          <Col
            md={6}
            className="d-flex align-items-center justify-content-center text-center"
          >
            <div>
              <span>
                <h1 style={{ textAlign: "left" }}>About Us </h1> <br />
                <p
                  style={{
                    fontSize: "20px",
                    textAlign: "justify",
                  }}
                >
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                  <b>Kathalaya Kids </b>
                  International Pre-school is an initiation by Mrs. Geetha
                  Ramanujam,Executive Director of Kathalaya Trust, an
                  internationally renowned storyteller, Educator, Academician,
                  Trainer, Administrator and an Ashoka fellow.
                  <b>Kathalaya Kids</b> is a fun playschool that caters to
                  children from 2- 6 years providing safe learning and nurturing
                  environment.
                  <br />
                  <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;We provide a
                  rich stimulating environment to satisfy the learning needs of
                  children for their holistic development.
                </p>
              </span>
              <br />
              <br />
            </div>
          </Col>
          <Col
            md={6}
            className="d-flex align-items-center justify-content-center text-center"
          >
            <img src={require("../asserts/hero.png")} alt=" " width={"80%"} />
          </Col>
        </Row>

        <span>
          <h1 className="aboutpage">Our Vision</h1> <br />
          <p
            style={{
              fontSize: "20px",
              justifyContent: "center",
              textAlign: "justify",
            }}
          >
            <LiaHandPointRight style={{ color: "#FF7F46", fontSize: "20px" }} />
            &nbsp; To establish “<b>Storification</b>” as an effective
            educational and cultural tool in all spheres of life and learning.
            <br />
            <LiaHandPointRight
              style={{ color: "#FF7F46", fontSize: "20px" }}
            />{" "}
            &nbsp; Aims to create mindful, compassionate and emotionally
            intelligent young leaders by building innovative and stimulating
            learning spaces.
          </p>
        </span>
        <br />
        <span>
          {" "}
          <h1 className="aboutpage">Our Mission</h1> <br />
          <p
            style={{
              fontSize: "20px",
              justifyContent: "center",
              textAlign: "justify",
            }}
          >
            <LiaHandPointRight style={{ color: "#FF7F46", fontSize: "20px" }} />{" "}
            &nbsp; To provide a stimulating environment to initiate a vibrant
            journey that taps, unlocks and enhance the potential of early
            learners.
            <br />
            <LiaHandPointRight
              style={{ color: "#FF7F46", fontSize: "20px" }}
            />{" "}
            &nbsp; To create gratifying moments of pride for the ambitious and
            encouraging parents.
            <br />
            <LiaHandPointRight
              style={{ color: "#FF7F46", fontSize: "20px" }}
            />{" "}
            &nbsp; To create an iconic franchise model that offers complete and
            unique services to the stakeholders.
          </p>
        </span>
      </Container>
    </>
  );
};
