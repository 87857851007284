import React from "react";
import "../styles/DayCar.css";
import { AiOutlineHome } from "react-icons/ai";
import { FaLongArrowAltRight } from "react-icons/fa";
import { Col, Container, Row } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import { LiaHandPointRight } from "react-icons/lia";
import { TbPointFilled } from "react-icons/tb";

export const DayCare = () => {
  return (
    <>
      <div id="show_bg_298">
        Day Care
        <ul className="no-bullets">
          <li>
            <a href="/">
              <AiOutlineHome /> &nbsp; Home
            </a>
          </li>
          <li>
            <a href="#home">
              <FaLongArrowAltRight /> &nbsp; Programmes
            </a>
          </li>
          <li>
            <a href="#home">
              <FaLongArrowAltRight /> &nbsp; Day Care
            </a>
          </li>
        </ul>
      </div>
      <Container>
        <br />
        <p style={{ fontSize: "20px" }}>
          <LiaHandPointRight style={{ color: "#FF7F46", fontSize: "30px" }} />{" "}
          &nbsp; Your beloved child deserves the best care.
        </p>
        <p style={{ fontSize: "20px" }}>
          <LiaHandPointRight style={{ color: "#FF7F46", fontSize: "30px" }} />{" "}
          &nbsp; We maintain Global quality standards of Safety, Hygiene,
          Infrastructure & care.
        </p>

        <p style={{ fontSize: "20px" }}>
          <LiaHandPointRight style={{ color: "#FF7F46", fontSize: "30px" }} />{" "}
          &nbsp; Kathalaya Daycare is a safe, secure and stimulating environment
          that positively engages and celebrates children and their families
          through a world class childcare support system.
        </p>

        <br />

        <img
          src={require("../asserts/daycare1.jpg")}
          width={"100%"}
          alt="Third slide"
        />

        <br />
        <br />
        <p style={{ fontSize: "35px", fontWeight: "600", color: "#ECB512" }}>
          Why KATHALAYA DAYCARE ?
        </p>

        <p style={{ fontSize: "20px", fontWeight: "600" }}>
          <LiaHandPointRight style={{ color: "#FF7F46", fontSize: "20px" }} />
          &nbsp; Teaching concepts through STORIES.
        </p>

        <p style={{ fontSize: "20px", fontWeight: "600" }}>
          <LiaHandPointRight style={{ color: "#FF7F46", fontSize: "20px" }} />
          &nbsp; Real-time mobile updates.
        </p>

        <p style={{ fontSize: "20px", fontWeight: "600" }}>
          <LiaHandPointRight style={{ color: "#FF7F46", fontSize: "20px" }} />
          &nbsp;Watch Live CCTV Footage.
        </p>

        <p style={{ fontSize: "20px", fontWeight: "600" }}>
          <LiaHandPointRight style={{ color: "#FF7F46", fontSize: "20px" }} />
          &nbsp; Tons of activities.
        </p>

        <p style={{ fontSize: "20px", fontWeight: "600" }}>
          <LiaHandPointRight style={{ color: "#FF7F46", fontSize: "20px" }} />
          &nbsp; Full attention to your child.
        </p>

        <p style={{ fontSize: "35px", fontWeight: "600", color: "#ECB512" }}>
          {" "}
          What parents say about KATHALAYA DAYCARE?{" "}
        </p>
        <p style={{ fontSize: "20px" }}>
          <TbPointFilled style={{ color: "#FF7F46", fontSize: "20px" }} />
          &nbsp; Teachers really take care of my child as their own, I am really
          happy and they are the reason because of which we are doing our jobs
          peacefully.
        </p>
        <p style={{ fontSize: "20px" }}>
          <TbPointFilled style={{ color: "#FF7F46", fontSize: "20px" }} />
          &nbsp; They provide a live cctv access, which is a plus point for me.I
          can see my child whenever I want and we have access to all the areas
          in the premises.
        </p>
        <p style={{ fontSize: "20px" }}>
          <TbPointFilled style={{ color: "#FF7F46", fontSize: "20px" }} />
          &nbsp; It’s a trust worthy place to drop your child in such a safe and
          secure premises, and watching them grow, returning home with a big
          smile on their face.
        </p>

        <p style={{ fontSize: "35px", fontWeight: "600", color: "#ECB512" }}>
          FAQ’S
        </p>
        <Accordion defaultActiveKey="0">
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              <p style={{ fontSize: "20px" }}>Structured routine</p>
            </Accordion.Header>
            <Accordion.Body style={{ fontSize: "20px" }}>
              <b>KATHALAYA DAYCARE</b>, have a routine that is followed on a
              daily basis. In our centre, the staff is flexible, letting babies
              follow their own natural rhythms. As children become older, the
              centres follow routines at certain times of the day and at other
              time.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>
              <p style={{ fontSize: "20px" }}>
                What do you do at &nbsp; <b>KATHALAYA DAYCARE</b> &nbsp; if the
                child won’t go to sleep?
              </p>
            </Accordion.Header>
            <Accordion.Body style={{ fontSize: "20px" }}>
              We won't force the child to go to sleep. We will take the child
              with us into the sleep room and keep him/her on our laps until
              s/he is ready to go to sleep. Sometimes, this can take a few days
              before the child feels comfortable enough to sleep.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header>
              <p style={{ fontSize: "20px" }}>
                Do the children have their own bed at &nbsp;{" "}
                <b>KATHALAYA DAYCARE </b> &nbsp;?{" "}
              </p>
            </Accordion.Header>
            <Accordion.Body style={{ fontSize: "20px" }}>
              Yes, they do. It is ensured that each child is provided with
              his/her own bed. The beds are cleaned regularly so that hygiene
              standards of the centre are maintained.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="3">
            <Accordion.Header>
              <p style={{ fontSize: "20px" }}>
                Will my child have to change his/her routine before commencing
                Daycare?
              </p>
            </Accordion.Header>
            <Accordion.Body style={{ fontSize: "20px" }}>
              No, absolutely not. We will adapt to your baby’s routine. If your
              baby likes to sleep two times a day, we will facilitate this. We
              will have a conversation with you before the baby joins our
              <b> KATHALAYA DAYCARE</b> and become familiar with the baby’s
              routine.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="4">
            <Accordion.Header>
              <p style={{ fontSize: "20px" }}>
                Does my child need to be toilet trained before starting at the
                Daycare centre?
              </p>
            </Accordion.Header>
            <Accordion.Body style={{ fontSize: "20px" }}>
              No, we do not set a specific age or time for your child to be
              toilet trained. Your child should only start toilet training when
              he/she is ready and shows a keen interest. Our staff is fully
              trained to support your child when he/she is ready and trains them
              for all their basic needs.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="5">
            <Accordion.Header>
              <p style={{ fontSize: "20px" }}>
                What is the checklist to be followed at the&nbsp;{" "}
                <b> KATHALAYA DAYCARE </b> &nbsp;?
              </p>
            </Accordion.Header>
            <Accordion.Body style={{ fontSize: "20px" }}>
              <p>
                <LiaHandPointRight
                  style={{ color: "#FF7F46", fontSize: "20px" }}
                />{" "}
                Diapers, Wet wipes, Rash Cream
              </p>
              <p>
                {" "}
                <LiaHandPointRight
                  style={{ color: "#FF7F46", fontSize: "20px" }}
                />{" "}
                3 Pair of Clothes & Under Garments
              </p>
              <p>
                {" "}
                <LiaHandPointRight
                  style={{ color: "#FF7F46", fontSize: "20px" }}
                />{" "}
                3 Milk Bottles, Formula Milk
              </p>
              <p>
                {" "}
                <LiaHandPointRight
                  style={{ color: "#FF7F46", fontSize: "20px" }}
                />{" "}
                Sipper, Moisturizer, Towel
              </p>
              <p>
                {" "}
                <LiaHandPointRight
                  style={{ color: "#FF7F46", fontSize: "20px" }}
                />{" "}
                All supplies in a Named Bag
              </p>
              <p>
                {" "}
                <LiaHandPointRight
                  style={{ color: "#FF7F46", fontSize: "20px" }}
                />{" "}
                Ensure to drop and pick your child on-time
              </p>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Container>
    </>
  );
};
