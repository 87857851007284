import React, { useEffect } from "react";
import { useState } from "react";
import "../styles/Gallary.css";
import { FaTimes } from "react-icons/fa";
import Loader from "../compoents/Loader";
import { AiOutlineHome } from "react-icons/ai";
import { FaLongArrowAltRight } from "react-icons/fa";
import "../styles/slider.css";

const Gallery = () => {
  const [model, setModel] = useState(false);
  const [tempimgSrc, setTempImgSrc] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);

  const getImg = (imgSrc) => {
    setTempImgSrc(imgSrc);
    setModel(true);
  };

  useEffect(() => {
    // Simulate data fetching with setTimeout
    setTimeout(() => {
      // Assuming you fetch the data here
      const fetchedData = [
        "https://firebasestorage.googleapis.com/v0/b/kathalaya-51358.appspot.com/o/1.jpeg?alt=media&token=e091c6f2-d8c6-4453-8227-fc5362c8c2cb",
        "https://firebasestorage.googleapis.com/v0/b/kathalaya-51358.appspot.com/o/10.jpeg?alt=media&token=ab4cc939-950a-4dfc-85e0-3975fb2ac313",
        "https://firebasestorage.googleapis.com/v0/b/kathalaya-51358.appspot.com/o/11.jpeg?alt=media&token=ebbdd36a-70ec-4961-aed7-3efed6a320fb",
        "https://firebasestorage.googleapis.com/v0/b/kathalaya-51358.appspot.com/o/12.jpeg?alt=media&token=1c730c20-29af-493a-a54f-52aa36cfae06",
        "https://firebasestorage.googleapis.com/v0/b/kathalaya-51358.appspot.com/o/13.jpeg?alt=media&token=08293c3b-6c5e-4904-8811-d8543bfd3a27",
        "https://firebasestorage.googleapis.com/v0/b/kathalaya-51358.appspot.com/o/14.jpeg?alt=media&token=443ef383-eab4-4719-8459-578d0b5db4a6",
        "https://firebasestorage.googleapis.com/v0/b/kathalaya-51358.appspot.com/o/15.jpeg?alt=media&token=72e65b0f-9a08-49f1-9270-6f2cb76b61ec",
        "https://firebasestorage.googleapis.com/v0/b/kathalaya-51358.appspot.com/o/16.jpeg?alt=media&token=ebc3dd1c-e9e9-4663-a56a-810fa96b22b4",
        "https://firebasestorage.googleapis.com/v0/b/kathalaya-51358.appspot.com/o/18.jpeg?alt=media&token=73669f53-05e9-4fe1-8795-417f3d112956",
        "https://firebasestorage.googleapis.com/v0/b/kathalaya-51358.appspot.com/o/19.jpeg?alt=media&token=beace946-369f-4f9e-b990-265664eb96e9",
        "https://firebasestorage.googleapis.com/v0/b/kathalaya-51358.appspot.com/o/19.jpeg?alt=media&token=beace946-369f-4f9e-b990-265664eb96e9",
        "https://firebasestorage.googleapis.com/v0/b/kathalaya-51358.appspot.com/o/2.jpeg?alt=media&token=9f11a02d-18d4-4b0f-a2f2-1f0c281743dc",
        "https://firebasestorage.googleapis.com/v0/b/kathalaya-51358.appspot.com/o/21.jpeg?alt=media&token=1b252cd7-413d-48ee-945e-801c7626373f",
        "https://firebasestorage.googleapis.com/v0/b/kathalaya-51358.appspot.com/o/13.jpeg?alt=media&token=08293c3b-6c5e-4904-8811-d8543bfd3a27",
        "https://firebasestorage.googleapis.com/v0/b/kathalaya-51358.appspot.com/o/23.jpeg?alt=media&token=2aaf5c84-ded9-4684-a060-e786ccc14cd0",
        "https://firebasestorage.googleapis.com/v0/b/kathalaya-51358.appspot.com/o/24.jpeg?alt=media&token=c56bdef7-6d9e-4003-a3ba-dc9266058456",
        "https://firebasestorage.googleapis.com/v0/b/kathalaya-51358.appspot.com/o/25.jpeg?alt=media&token=514d1f21-e571-4448-83fc-303d8a2a3533",
      ];
      setData(fetchedData);
      setIsLoading(false);
    }, 2000); // Simulating a delay of 2 seconds
  }, []);

  console.log(data);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <div id="show_bg_2982g">
        Gallery
        <ul className="no-bullets">
          <li>
            <a href="/">
              <AiOutlineHome /> &nbsp; Home
            </a>
          </li>

          <li>
            <a href="#home">
              <FaLongArrowAltRight /> &nbsp; Gallery
            </a>
          </li>
        </ul>
      </div>
      <br />
      <div id="gallery">
        <div className="container">
          <div className={model ? "model open" : "model"}>
            <img src={tempimgSrc} alt="" width="100%" />
            <FaTimes onClick={() => setModel(false)} />
          </div>
          <div className="gall">
            {data.map((item, index) => {
              return (
                <div
                  className="pics"
                  key={index}
                  onClick={() => getImg(item.imgSrc)}
                >
                  <img src={item} alt="Loading.." width="100%" />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default Gallery;
