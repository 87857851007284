import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import { AiOutlineHome } from "react-icons/ai";
import { FaLongArrowAltRight } from "react-icons/fa";
import "../styles/slider.css";

export const Learning = () => {
  return (
    <>
      <div id="show_bg_29821">
        Learning Environment
        <ul className="no-bullets">
          <li>
            <a href="/">
              <AiOutlineHome /> &nbsp; Home
            </a>
          </li>
          <li>
            <a href="#home">
              <FaLongArrowAltRight /> &nbsp; Why Kathalaya
            </a>
          </li>
          <li>
            <a href="#home">
              <FaLongArrowAltRight /> &nbsp; Learning Environment
            </a>
          </li>
        </ul>
      </div>
      <Container>
        <br />
        <Row>
          <p
            style={{
              fontSize: "35px",
              fontWeight: "600",
              color: "#ECB512",
            }}
          >
            We believe…
          </p>
          <p
            style={{
              fontSize: "20px",
              textAlign: "justify",
              textIndent: "50px",
            }}
          >
            <b>Kathalaya Kids</b> International pre-schools, is a safe learning
            environment where children and teachers together explore the
            possibilities -Instilling a love for school and learning.
          </p>
          <p
            style={{
              fontSize: "20px",
              textAlign: "justify",
              textIndent: "50px",
            }}
          >
            Kathalaya's new methodology of Education will be to Storifying
            Learning –The all new way of dressing up concepts in Education. This
            methodology will enable lessons to be more comprehensible, relatable
            and relevant, consequently increasing retention.
          </p>
          <p
            style={{
              fontSize: "20px",
              textAlign: "justify",
              textIndent: "50px",
            }}
          >
            When lessons are communicated through stories that students identify
            with, and through meaningful child centric activities will be able
            to retain recall and retell concepts in education with ease and
            confidence.
          </p>
          <p
            style={{
              fontSize: "20px",
              textAlign: "justify",
              textIndent: "50px",
            }}
          >
            The programmes and curriculum at <b>Kathalaya</b> have been designed
            as a perfect blend of traditional learning <b>“Storification”</b>,
            techonology assistance and gamification (activity-based learning).
          </p>
        </Row>
      </Container>
    </>
  );
};
