import { Row, Col, Container } from "react-bootstrap";
import Carousel from "react-bootstrap/Carousel";
import Modal from "react-bootstrap/Modal";
import { useState } from "react";
import "../styles/Responsive.css";

function CarouselFadeExample() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Carousel fade>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={require("../asserts/Banner1.webp")}
            width="100%"
            alt="First slide"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={require("../asserts/Banner2.webp")}
            alt="Second slide"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={require("../asserts/Banner3.webp")}
            alt="Third slide"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={require("../asserts/Banner4.webp")}
            alt="Third slide"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={require("../asserts/Banner5.webp")}
            alt="Third slide"
          />
        </Carousel.Item>
      </Carousel>
      <div style={{ backgroundColor: "#7690c9" }}>
        <Container>
          <Row>
            <Col
              md={9}
              className="d-flex justify-content-center align-items-center"
            >
              <p
                style={{
                  padding: "10px",
                  fontSize: "25px",
                  fontWeight: "600",
                  color: "#FFF",
                }}
                className="qoute"
              >
                "Unlock your child's potential from the very start... <br />{" "}
                &nbsp;&nbsp; - Their journey towards lifelong learning begins
                here.."
              </p>
            </Col>
            <Col
              md={3}
              className="d-flex justify-content-center align-items-center"
            >
              <button id="btn-pre-v2" onClick={handleShow}>
                ENQUIRE NOW
              </button>
            </Col>
          </Row>
        </Container>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Enquire Now</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div class="holds-the-iframe">
              <iframe
                src="https://script.google.com/macros/s/AKfycbwlCYC5zyxh7nyh54SJCUGK58xzUT9BOlS402ySx-j9o-RS6cSFQLI10Ec-lBTgS_ud-w/exec"
                title="locationm"
                width="100%"
                height="550px"
                style={{ border: 0 }}
                aria-hidden="false"
              />
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
}

export default CarouselFadeExample;
